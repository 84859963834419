import {get,has,forOwn,set,round} from 'lodash';

const REPS_SCORE = [1.0, .96, .92, .89, .86, .83, .81, .79];

function processInputData(unit,inputData) {
    let myFormData = {};
    forOwn(inputData, (o, k) => {

        // TIME - Minutes and Seconds -> Minutes
        if (has(o, 'minutes') && has(o, 'seconds')) {
            let minutes = round(get(o, 'seconds') / 60 + get(o, 'minutes'),2);
            set(myFormData, k, minutes);
        }

        // TIME - Minutes and Hours -> Minutes
        if (has(o, 'minutes') && has(o, 'hours')) {
            set(myFormData, k, get(o, 'hours') * 60 + get(o, 'minutes'));
        }

        // TIME - Seconds -> Seconds
        if (has(o, 'seconds') && !has(o, 'hours') && !has(o, 'minutes')) {
            console.log('seconds');
            set(myFormData, k, get(o,'seconds'));
        }

        // TIME - Minutes -> Minutes
        if (has(o, 'minutes') && !has(o, 'hours') && !has(o, 'seconds')) {
            set(myFormData, k, get(o,'minutes'));
        }

        // WEIGHT AND REPS - Convert to score
        // If the data is weight and reps
        if(has(o, 'weight') && has(o, 'reps')) {
          let score = convertWeightRepsToScore(unit,o);
          set(myFormData, k, score);
        } else{
            // Just reps
            if(has(o, 'reps') && has(o, 'weight') == false) {
                set(myFormData, k, get(o, 'reps'));
            }
        }
        // If the data is weight and nothing else, pass weight as is
        if(has(o, 'weight') && !has(o, 'reps')) {
            set(myFormData, k, get(o,'weight'));
        }

        // DISTANCE - convert 
        // If the data is weight and reps
        if(has(o, 'feet') || has(o, 'meters')) {
          let feet = convertDistanceToFeet(unit,o);
          set(myFormData, k, feet);
        }


        // Just answer
        if(has(o, 'answer')) {
            set(myFormData, k, get(o, 'answer'));
        }
        // Just points
        if(has(o, 'points')) {
            set(myFormData, k, get(o, 'points'));
        }
        // Just val
        if(has(o, 'val')) {
            set(myFormData, k, get(o, 'val'));
        }
        
    });

    return myFormData;
}

function convertWeightRepsToScore(unit,o) {
    let weight = get(o, 'weight');
    let reps = get(o, 'reps');

    // If the weight is in metric
    if (unit == 'metric') {
        weight = weight * 2.2046;
    }

    let score = REPS_SCORE[reps - 1];
    let total = round(weight / score);
    return total;
}

function convertDistanceToFeet(unit,o) {
    let feet = get(o, 'feet');

    // Convert meters to feet
    if (unit == 'metric') {
        let meters = get(o, 'meters');
        feet = meters * 3.28084;

        let cm = get(o,'cm');
        if(cm) {
            feet = feet + (cm / 30.48);
        }

    } else {
        feet = get(o, 'feet');

        // Convert the inches to feet
        if(has(o,'inches')) {
            let inches = get(o, 'inches');
            feet = feet + (inches * 0.0833333);
        }
    }
    return round(feet,2);
}

export default {
    processInputData
}