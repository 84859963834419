<template>
    <div>
      <a class="logo-link" @click="onClickFirst">
        <Logo />
      </a>
      <div class="stepper">
        <div class="progress-wrapper" v-if="current_step_num > 1 && current_step_num < 5">
          <b-progress variant="success" >
            <b-progress-bar :value="progress" :label="`${((current_step_num / max_step) * 100).toFixed(0)}%`"/>
          </b-progress>
        </div>

        <b-card class="card-style" >
          <b-card-text>
            <router-view></router-view>
          </b-card-text>
        </b-card>

      </div>
    </div>
</template>

<style lang="scss" scoped>
.logo-link {
  cursor: pointer;
}
.progress-wrapper {
  margin-top: 40px !important;
}
</style>

<script>
import Logo from './Logo.vue'
// import Intro from './steps/Intro.vue';
// import ProfileSelect from './steps/ProfileSelect.vue';
// import Strength from './steps/Strength.vue';
// import WorkCapacity from './steps/WorkCapacity.vue';
// import Conditioning from './steps/Conditioning.vue';
// import Movement from './steps/Movement.vue';
// import Recovery from './steps/Recovery.vue';
// import Finished from './steps/Finished.vue';

export default {
  name: 'Stepper',
  components: {
    Logo,
    // Intro,
    // ProfileSelect,
    // Strength,
    // WorkCapacity,
    // Conditioning,
    // Recovery,
    // Movement,
    // Finished
  },
  data() {
    return {
      current_step_num: 0,
      current_step: null,
      // steps: [
      //   "intro",
      //   "profile-select",
      //   "strength",
      //   "work-capacity",
      //   "conditioning",
      //   "movement",
      //   "recovery",
      //   "finished"
      // ],
      max_step: 5,
      // isProfileSelectNextEnabled: process.env.VUE_APP_IS_ALL_VALID ? true : false,
      // isStrengthNextEnabled: process.env.VUE_APP_IS_ALL_VALID ? true : false,
    };
  },
  computed: {
    progress: function() {
      return Math.round(100 / this.max_step) * (this.$store.state.currentStepIndex);
    }
  },
  mounted() {
    // this.current_step = this.steps[this.$store.state.currentStepIndex];
    if(this.$route.name) {
      let stepIndex = this.$store.state.currentProfileConfigSteps.indexOf(this.$route.name);
      console.log('Resuming from index: ' + stepIndex);
      this.$store.dispatch('setCurrentStep', stepIndex);
      this.current_step_num = stepIndex;
    }
  },
  watch: {
    '$store.state.currentStepIndex': function() {
      console.log(this.$store.state.currentStepIndex)
      this.updatePosition();
      window.scrollTo(0,0);
      this.current_step_num = this.$store.state.currentStepIndex;
    },
    '$store.state.currentProfileConfigSteps': function() {
      this.max_step = (this.$store.state.currentProfileConfigSteps.length - 1);
    },
  },
  methods: {
    onClickFirst: function() {
      this.$store.dispatch('startOver');
    },
    updatePosition() {
      console.log('updatePosition');
      let stepName = this.$store.state.currentProfileConfigSteps[this.$store.state.currentStepIndex];
      console.log('CURRENT STEP INDEX: ' + this.$store.state.currentStepIndex);
      console.log('CURRENT STEP NAME: ' + stepName);
      if(this.$route.name != stepName) {
        this.$router.push({
          name: stepName
        })
        .catch(error => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
      }
    },
    onProfileValid(val) {
      this.isProfileSelectNextEnabled = val;
    },
    onStrengthValid(val) {
      this.isStrengthNextEnabled = val;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.stepper {
  margin-bottom: 40px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.progress-wrapper {
  width: 80%;
  margin: auto;
  display: block;
}
.progress {
    max-width: 600px;
    margin: auto;
    height: 2rem;
    font-size: 1.25rem;
}
</style>
<style lang="scss" >
.progress-bar {
  background-color: #ecb643 !important;
  font-weight: bold;
  color: #113653;
}
</style>
