<template>
    <div class="container step-container">

        <b-form @submit="onSubmit">
            <StepTitle title="Strength Assessments" />

            <div class="step-copy">
                <p>Strength is the peak force that can be produced during a movement. In other words, it’s the amount of force you can apply to an external object (ground, barbell, log, another human, etc) during any activity. More simply, it’s the ability to lift heavy things and move them around. </p>
                <p>How much you deadlift is a form of strength, but it only loosely correlates with the strength used to carry a 70-pound ruck for 12 miles, scale a wall, or drag a partner. Intelligent strength training combined with an emphasis on maintaining movement quality will develop a broad base of strength that will allow you to transfer general capacities (think squatting, lunging, deadlifting, pressing, pulling) to specific capabilities in your job. </p>
                <p>Choose the exercise variation you prefer for each movement category using the dropdowns. The ‘i’ button that pops up after selecting an exercise provides more information on the movement and testing protocol. If there is no dropdown, there is only one option for that category. You can do multiple strength tests and your power test on the same day. But, make sure it’s a day where you’re fully rested. If you’ve recently (in the last 90 days) worked up to a 3-8 rep max in one of the exercises below, you can input that data without repeating the test. Otherwise, do the testing so that you have accurate data or your profile won’t be reflective of your true needs.</p>
            </div>

            <span class="exercise-label">READ THE INFO BUTTONS TO MAKE SURE YOU ARE DOING THE TESTS PROPERLY!</span>

            <b-button class="center tp-btn" v-b-modal.tp-strength-modal variant="primary">
                Testing Protocol
            </b-button>

            <div class="step-inputs">

                <div class="broad-jump exercise-item" v-if="broad_jump">
                    <div class="row" >
                        <div class="col-12">
                            <span class="exercise-label">Broad Jump</span>
                        </div>
                    </div>
                    <div class="row mt1" >
                        <div class="col-3 v-center-wrapper">
                            <b-button type="button" class="info-btn btn-secondary v-center" v-b-modal.broad-jump-modal><i class="bi bi-info-lg"></i></b-button>
                        </div>
                        <div class="col-9">
                            <div class="row" v-if="distanceUnit === 'ft'">
                                <div class="col-6">
                                    <b-form-select v-model="inputData.broad_jump.feet" :options="feetOptions" size="lg" required>
                                        <b-form-select-option :value="null" disabled selected>Feet</b-form-select-option>
                                    </b-form-select>
                                </div>
                                <div class="col-6">
                                    <b-form-select v-model="inputData.broad_jump.inches" :options="inchOptions" size="lg" required>
                                        <b-form-select-option :value="null" disabled>Inches</b-form-select-option>
                                    </b-form-select>
                                </div>
                            </div>
                            <div class="row" v-if="distanceUnit == 'm'">
                                <div class="col-6">
                                    <b-form-group>
                                        <b-form-select v-model="inputData.broad_jump.meters" :options="meterOptions" size="lg" required>
                                            <b-form-select-option :value="null" disabled selected>Meters</b-form-select-option>
                                        </b-form-select>
                                    </b-form-group>
                                </div>
                                <div class="col-6">
                                    <b-form-select v-model="inputData.broad_jump.cm" :options="cmOptions" size="lg" required>
                                        <b-form-select-option :value="null" disabled>Centimeters</b-form-select-option>
                                    </b-form-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="squats exercise-item" v-if="squats">
                    <b-form-group label="Squat Exercise">
                        <b-form-select v-model="inputData.squatType" :options="squats" size="lg" required>
                        </b-form-select>
                    </b-form-group>
                    
                    <div class="row" v-if="inputData.squatType != null">
                        <div class="col-4">
                            <b-button type="button" class="info-btn btn-secondary" v-show="inputData.squatType == 'back_squat'"
                                v-b-modal.back-squat-modal><i class="bi bi-info-lg"></i></b-button>
                            <b-button type="button" class="info-btn btn-secondary" v-show="inputData.squatType == 'front_squat'"
                                v-b-modal.front-squat-modal><i class="bi bi-info-lg"></i></b-button>
                            <b-button type="button" class="info-btn btn-secondary" v-show="inputData.squatType == 'goblet_squat'"
                                v-b-modal.goblet-squat-modal><i class="bi bi-info-lg"></i></b-button>
                        </div>

                        <!-- Back Squat -->
                        <div class="col-4" v-if="inputData.squatType == 'back_squat'">
                            <b-form-input type="number" v-if="weightUnit == 'lbs'" v-model="inputData['back_squat'].weight" placeholder="Weight (lbs)" min="1" max="999" maxlength="3" required 
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                            </b-form-input>
                            <b-form-input type="number" v-if="weightUnit == 'kg'" v-model="inputData['back_squat'].weight" placeholder="Weight (kgs)" min="1" max="454" maxlength="3" required 
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                            </b-form-input>
                        </div>
                        <div class="col-4" v-if="inputData.squatType == 'back_squat'">
                            <b-form-input type="number" v-model="inputData['back_squat'].reps" placeholder="Reps" min="1" max="8" maxlength="1" required
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                            </b-form-input>
                        </div>

                        <!-- Front Squat -->
                        <div class="col-4" v-if="inputData.squatType == 'front_squat'">
                            <b-form-input type="number" v-if="weightUnit == 'lbs'" v-model="inputData['front_squat'].weight" placeholder="Weight (lbs)" min="1" max="999" maxlength="3" required 
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                            </b-form-input>
                            <b-form-input type="number" v-if="weightUnit == 'kg'" v-model="inputData['front_squat'].weight" placeholder="Weight (kgs)" min="1" max="454" maxlength="3" required 
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                            </b-form-input>
                        </div>
                        <div class="col-4" v-if="inputData.squatType == 'front_squat'">
                            <b-form-input type="number" v-model="inputData['front_squat'].reps" placeholder="Reps" min="1" max="8" maxlength="1" required
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                            </b-form-input>
                        </div>

                        <!-- Goblet Squat -->
                        <div class="col-4" v-if="inputData.squatType == 'goblet_squat'">
                            <b-form-input type="number" v-if="weightUnit == 'lbs'" v-model="inputData['goblet_squat'].weight" placeholder="Weight (lbs)" min="1" max="999" maxlength="3" required 
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                            </b-form-input>
                            <b-form-input type="number" v-if="weightUnit == 'kg'" v-model="inputData['goblet_squat'].weight" placeholder="Weight (kgs)" min="1" max="454" maxlength="3" required 
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                            </b-form-input>
                        </div>
                    </div>
                </div>

                <div class="deadlift exercise-item" v-if="deadlifts">
                    <b-form-group label="Deadlift or Posterior Dominant Exercise">
                        <b-form-select v-model="inputData.deadliftType" :options="deadlifts" size="lg" required>
                        </b-form-select>
                    </b-form-group>

                    <div class="row" v-if="inputData.deadliftType != null">
                        <div class="col-4">
                            <b-button type="button" class="info-btn btn-secondary" v-show="inputData.deadliftType == 'straight_bar_dl'"
                                v-b-modal.straight-bar-dl-modal><i class="bi bi-info-lg"></i></b-button>
                            <b-button type="button" class="info-btn btn-secondary" v-show="inputData.deadliftType == 'trap_bar_dl'"
                                v-b-modal.trap-bar-dl-modal><i class="bi bi-info-lg"></i></b-button>
                            <b-button type="button" class="info-btn btn-secondary" v-show="inputData.deadliftType == 'rfe_split_squat' || inputData.deadliftType == 'rfe_split_squat_x8'"
                                v-b-modal.rfe-split-squat-modal><i class="bi bi-info-lg"></i></b-button>
                        </div>

                        <div class="col-4" v-if="inputData.deadliftType == 'straight_bar_dl'">
                            <b-form-input type="number" v-if="weightUnit == 'lbs'" v-model="inputData['straight_bar_dl'].weight" placeholder="Weight (lbs)" min="1" max="999" maxlength="3" required 
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                            </b-form-input>
                            <b-form-input type="number" v-if="weightUnit == 'kg'" v-model="inputData['straight_bar_dl'].weight" placeholder="Weight (kgs)" min="1" max="454" maxlength="3" required 
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                            </b-form-input>
                        </div>
                        <div class="col-4" v-if="inputData.deadliftType == 'straight_bar_dl'">
                            <b-form-input type="number" v-model="inputData['straight_bar_dl'].reps" placeholder="Reps" min="1" max="8" maxlength="1" required
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                            </b-form-input>
                        </div>

                        <div class="col-4" v-if="inputData.deadliftType == 'trap_bar_dl'">
                            <b-form-input type="number" v-if="weightUnit == 'lbs'" v-model="inputData['trap_bar_dl'].weight" placeholder="Weight (lbs)" min="1" max="999" maxlength="3" required 
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                            </b-form-input>
                            <b-form-input type="number" v-if="weightUnit == 'kg'" v-model="inputData['trap_bar_dl'].weight" placeholder="Weight (kgs)" min="1" max="454" maxlength="3" required 
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                            </b-form-input>
                        </div>
                        <div class="col-4" v-if="inputData.deadliftType == 'trap_bar_dl'">
                            <b-form-input type="number" v-model="inputData['trap_bar_dl'].reps" placeholder="Reps" min="1" max="8" maxlength="1" required
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                            </b-form-input>
                        </div>

                        <div class="col-4" v-if="inputData.deadliftType == 'rfe_split_squat'">
                            <b-form-input type="number" v-if="weightUnit == 'lbs'" v-model="inputData['rfe_split_squat'].weight" placeholder="Weight (lbs)" min="1" max="999" maxlength="3" required 
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                            </b-form-input>
                            <b-form-input type="number" v-if="weightUnit == 'kg'" v-model="inputData['rfe_split_squat'].weight" placeholder="Weight (kgs)" min="1" max="454" maxlength="3" required 
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                            </b-form-input>
                        </div>
                        <div class="col-4" v-if="inputData.deadliftType == 'rfe_split_squat'">
                            <b-form-input type="number" v-model="inputData['rfe_split_squat'].reps" placeholder="Reps" min="1" max="8" maxlength="1" required
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                            </b-form-input>
                        </div>

                        <div class="col-8" v-if="inputData.deadliftType == 'rfe_split_squat_x8'">
                            <b-form-input type="number" v-if="weightUnit == 'lbs'" v-model="inputData['rfe_split_squat_x8'].weight" placeholder="Weight (lbs)" min="1" max="999" maxlength="3" required 
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                            </b-form-input>
                            <b-form-input type="number" v-if="weightUnit == 'kg'" v-model="inputData['rfe_split_squat_x8'].weight" placeholder="Weight (kgs)" min="1" max="454" maxlength="3" required 
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                            </b-form-input>
                        </div>
                    </div>
                </div>

                <div class="upper-body-push exercise-item" v-if="upperBodyPush">
                    <b-form-group label="Upper Body Push Exercise">
                        <b-form-select v-model="inputData.upperBodyPushType" :options="upperBodyPush" size="lg" required>
                        </b-form-select>
                    </b-form-group>

                    <div class="row" v-if="inputData.upperBodyPushType != null">
                        <div class="col-4">
                            <b-button type="button" class="info-btn btn-secondary" v-show="inputData.upperBodyPushType == 'db_bench_press'"
                                v-b-modal.db-bench-press-modal><i class="bi bi-info-lg"></i></b-button>
                            <b-button type="button" class="info-btn btn-secondary" v-show="inputData.upperBodyPushType == 'bench_press'"
                                v-b-modal.bench-press-modal><i class="bi bi-info-lg"></i></b-button>
                            <b-button type="button" class="info-btn btn-secondary" v-show="inputData.upperBodyPushType == 'landmine_press' || inputData.upperBodyPushType == 'landmine_press_x5'"
                                v-b-modal.landmine-press-modal><i class="bi bi-info-lg"></i></b-button>
                            <b-button type="button" class="info-btn btn-secondary" v-show="inputData.upperBodyPushType == 'kb_oh_press'"
                                v-b-modal.kb-oh-press-modal><i class="bi bi-info-lg"></i></b-button>
                            
                        </div>
                        
                        <!-- DB Bench Press -->
                        <div class="col-8" v-if="inputData.upperBodyPushType == 'db_bench_press'">
                            <b-form-input type="number" v-if="weightUnit == 'lbs'" v-model="inputData['db_bench_press'].weight" placeholder="Weight (lbs)" min="1" max="999" maxlength="3" required 
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                            </b-form-input>
                            <b-form-input type="number" v-if="weightUnit == 'kg'" v-model="inputData['db_bench_press'].weight" placeholder="Weight (kgs)" min="1" max="454" maxlength="3" required 
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                            </b-form-input>
                        </div>
                        
                        <!-- Bench Press -->
                        <div class="col-4" v-if="inputData.upperBodyPushType == 'bench_press'">
                            <b-form-input type="number" v-if="weightUnit == 'lbs'" v-model="inputData['bench_press'].weight" placeholder="Weight (lbs)" min="1" max="999" maxlength="3" required 
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                            </b-form-input>
                            <b-form-input type="number" v-if="weightUnit == 'kg'" v-model="inputData['bench_press'].weight" placeholder="Weight (kgs)" min="1" max="454" maxlength="3" required 
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                            </b-form-input>
                        </div>
                        <div class="col-4" v-if="inputData.upperBodyPushType == 'bench_press'">
                            <b-form-input type="number" v-model="inputData['bench_press'].reps" placeholder="Reps" min="1" max="8" maxlength="1"  required
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                            </b-form-input>
                        </div>

                        <!-- Landmine Press -->
                        <div class="col-4" v-if="inputData.upperBodyPushType == 'landmine_press'">
                            <b-form-input type="number" v-if="weightUnit == 'lbs'" v-model="inputData['landmine_press'].weight" placeholder="Weight (lbs)" min="1" max="999" maxlength="3" required 
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                            </b-form-input>
                            <b-form-input type="number" v-if="weightUnit == 'kg'" v-model="inputData['landmine_press'].weight" placeholder="Weight (kgs)" min="1" max="454" maxlength="3" required 
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                            </b-form-input>
                        </div>
                        <div class="col-4" v-if="inputData.upperBodyPushType == 'landmine_press'">
                            <b-form-input type="number" v-model="inputData['landmine_press'].reps" placeholder="Reps" min="1" max="8" maxlength="1"  required
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                            </b-form-input>
                        </div>

                        <!-- Landmine Press X5 -->
                        <div class="col-8" v-if="inputData.upperBodyPushType == 'landmine_press_x5'">
                            <b-form-input type="number" v-if="weightUnit == 'lbs'" v-model="inputData['landmine_press_x5'].weight" placeholder="Weight (lbs)" min="1" max="999" maxlength="3" required 
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                            </b-form-input>
                            <b-form-input type="number" v-if="weightUnit == 'kg'" v-model="inputData['landmine_press_x5'].weight" placeholder="Weight (kgs)" min="1" max="454" maxlength="3" required 
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                            </b-form-input>
                        </div>
                        
                        <!-- KB OH Press -->
                        <div class="col-4" v-if="inputData.upperBodyPushType == 'kb_oh_press'">
                            <b-form-input type="number" v-if="weightUnit == 'lbs'" v-model="inputData['kb_oh_press'].weight" placeholder="Weight (lbs)" min="1" max="999" maxlength="3" required 
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                            </b-form-input>
                            <b-form-input type="number" v-if="weightUnit == 'kg'" v-model="inputData['kb_oh_press'].weight" placeholder="Weight (kgs)" min="1" max="454" maxlength="3" required 
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                            </b-form-input>
                        </div>
                        <div class="col-4" v-if="inputData.upperBodyPushType == 'kb_oh_press'">
                            <b-form-input type="number" v-model="inputData['kb_oh_press'].reps" placeholder="Reps" min="1" max="8" maxlength="1"  required
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                            </b-form-input>
                        </div>
                    </div>
                </div>

                <div class="upper-body-pull exercise-item" v-if="upperBodyPull">
                    <b-form-group label="Upper Body Pull">
                        <b-form-select v-model="inputData.upperBodyPullType" :options="upperBodyPull" size="lg" required>
                        </b-form-select>
                    </b-form-group>

                    <div class="row" v-if="inputData.upperBodyPullType != null">
                        <div class="col-4">
                            <b-button type="button" class="info-btn btn-secondary" v-b-modal.pullup-modal v-show="inputData.upperBodyPullType == 'pullups'"><i class="bi bi-info-lg"></i>
                            </b-button>
                            <b-button type="button" class="info-btn btn-secondary" v-b-modal.db-row-modal v-show="inputData.upperBodyPullType == 'db_row' || inputData.upperBodyPullType == 'db_row_x5'"><i class="bi bi-info-lg"></i>
                            </b-button>
                        </div>

                        <!-- Pullups -->
                        <div class="col-md-5 col-sm-12 input-margin-bottom" v-if="inputData.upperBodyPullType == 'pullups'">
                            <b-form-input type="number" v-if="weightUnit == 'lbs'" v-model="inputData['pullups'].weight" placeholder="BW + External Weight (lbs)" min="1" max="999" maxlength="3" required 
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                            </b-form-input>
                            <b-form-input type="number" v-if="weightUnit == 'kg'" v-model="inputData['pullups'].weight" placeholder="BW + External Weight (kgs)" min="1" max="454" maxlength="3" required 
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                            </b-form-input>
                        </div>
                        <div class="col-md-3 col-sm-12 input-margin-bottom" v-if="inputData.upperBodyPullType == 'pullups'">
                            <b-form-input type="number" v-model="inputData['pullups'].reps" placeholder="Reps" min="1" max="8" maxlength="1" 
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                            </b-form-input>
                        </div>

                        <!-- DB Row (10) -->
                        <div class="col-4" v-if="inputData.upperBodyPullType == 'db_row'">
                            <b-form-input type="number" v-if="weightUnit == 'lbs'" v-model="inputData['db_row'].weight" placeholder="Weight (lbs)" min="1" max="999" maxlength="3" required 
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                            </b-form-input>
                            <b-form-input type="number" v-if="weightUnit == 'kg'" v-model="inputData['db_row'].weight" placeholder="Weight (kgs)" min="1" max="454" maxlength="3" required 
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                            </b-form-input>
                        </div>

                        <!-- DB Row X5 -->
                        <div class="col-8" v-if="inputData.upperBodyPullType == 'db_row_x5'">
                            <b-form-input type="number" v-if="weightUnit == 'lbs'" v-model="inputData['db_row_x5'].weight" placeholder="Weight (lbs)" min="1" max="999" maxlength="3" required 
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                            </b-form-input>
                            <b-form-input type="number" v-if="weightUnit == 'kg'" v-model="inputData['db_row_x5'].weight" placeholder="Weight (kgs)" min="1" max="454" maxlength="3" required 
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                            </b-form-input>
                        </div>
                    </div>
                </div>
                
            </div>

            <!-- Modals -->
            <b-modal id="tp-strength-modal" title="Testing Protocol" hide-footer size="xl" scrollable>
                <TPStrengthModal />
            </b-modal>
            <b-modal id="back-squat-modal" title="Back Squat" hide-footer size="xl" scrollable>
                <BackSquatModal />
            </b-modal>
            <b-modal id="front-squat-modal" title="Front Squat" hide-footer size="xl" scrollable>
                <FrontSquatModal />
            </b-modal>
            <b-modal id="goblet-squat-modal" title="Goblet Squat" hide-footer size="xl" scrollable>
                <GobletSquatModal />
            </b-modal>
            
            <b-modal id="straight-bar-dl-modal" title="Barbell Deadlift" hide-footer size="xl" scrollable>
                <StraightbarDlModal />
            </b-modal>
            <b-modal id="trap-bar-dl-modal" title="Trap Bar Deadlift" hide-footer size="xl" scrollable>
                <TrapbarDlModal />
            </b-modal>
            <b-modal id="rfe-split-squat-modal" title="RFE Split Squat" hide-footer size="xl" scrollable>
                <RFESplitSquatModal />
            </b-modal>
            
            <b-modal id="bench-press-modal" title="Bench Press" hide-footer size="xl" scrollable>
                <BenchPressModal />
            </b-modal>
            <b-modal id="db-bench-press-modal" title="DB Bench Press" hide-footer size="xl" scrollable>
                <DBBenchPressModal />
            </b-modal>
            <b-modal id="landmine-press-modal" title="Landmine Press" hide-footer size="xl" scrollable>
                <LandminePressModal />
            </b-modal>
            <b-modal id="kb-oh-press-modal" title="KB Overhead Press" hide-footer size="xl" scrollable>
                <KBOverheadPressModal />
            </b-modal>

            <b-modal id="pullup-modal" title="Pullup" hide-footer size="xl" scrollable>
                <PullupModal />
            </b-modal>
            <b-modal id="db-row-modal" title="DB Row" hide-footer size="xl" scrollable>
                <DBRowModal />
            </b-modal>

            <b-modal id="broad-jump-modal" title="Broad Jump" hide-footer size="xl" scrollable>
                <BroadJumpModal />
            </b-modal>

            <div class="step-controls row">
                <div class="col-12">
                    <b-button class="float-left" variant="secondary" size="lg" @click="onClickBack">Back</b-button>
                    <b-button type="submit" class="float-right" variant="primary" size="lg" >Next</b-button>
                </div>
            </div>
        </b-form>

    </div>

</template>

<style lang="scss" scoped>

@media (max-width: 768px) {
   .input-margin-bottom {
       margin-top: 10px;
    //    margin-bottom: 10px;
   }
}
</style>

<script>
    import {
        mapState
    } from 'vuex'

    import StepTitle from '../StepTitle.vue';

    import TPStrengthModal from '../modals/tp-strength-modal.vue'
    import BackSquatModal from '../modals/back-squat-modal.vue'
    import FrontSquatModal from '../modals/front-squat-modal.vue'
    import GobletSquatModal from '../modals/goblet-squat-modal.vue'
    import StraightbarDlModal from '../modals/straight-bar-dl-modal.vue'
    import TrapbarDlModal from '../modals/trap-bar-dl-modal.vue'
    import RFESplitSquatModal from '../modals/rfe-split-squat-modal.vue'
    import BenchPressModal from '../modals/bench-press-modal.vue'
    import DBBenchPressModal from '../modals/db-bench-press-modal.vue'
    import LandminePressModal from '../modals/landmine-press-modal.vue'
    import KBOverheadPressModal from '../modals/kb-overhead-press-modal.vue'
    import DBRowModal from '../modals/db-row-modal.vue'
    import PullupModal from '../modals/pullup-modal.vue'
    import BroadJumpModal from '../modals/broad-jump-modal.vue'

    import {
        filter, forEach, has
    } from 'lodash';

    export default {
        components: {
            StepTitle,
            TPStrengthModal,
            BackSquatModal,
            FrontSquatModal,
            GobletSquatModal,
            StraightbarDlModal,
            TrapbarDlModal,
            RFESplitSquatModal,
            BenchPressModal,
            DBBenchPressModal,
            LandminePressModal,
            KBOverheadPressModal,
            DBRowModal,
            PullupModal,
            BroadJumpModal
        },
        data() {
            return {
                form: {
                    broad_jump: {
                        distance: null
                    },
                    squatType: null,
                    squat: {
                        weight: null,
                        reps: null
                    },
                    deadliftType: null,
                    deadlift: {
                        weight: null,
                        reps: null
                    },
                    pressType: null,
                    press: {
                        weight: null,
                        reps: null
                    },
                    pullupType: null,
                    pullup: {
                        weight: null,
                        reps: null
                    },
                    rfe_split_squat: {
                        weight: null,
                        reps: null
                    }
                },
                broad_jump: null,
                squats: null,
                allSquats: [{
                        text: 'Choose an exercise',
                        value: null
                    },
                    {
                        text: 'Back Squat',
                        value: 'back_squat'
                    },
                    {
                        text: 'Front Squat',
                        value: 'front_squat'
                    },
                    {
                        text: 'Goblet Squat',
                        value: 'goblet_squat'
                    }
                ],
                deadlifts: null,
                allDeadlifts: [{
                        text: 'Choose an exercise',
                        value: null
                    },
                    {
                        text: 'Trap Bar Deadlift',
                        value: 'trap_bar_dl'
                    },
                    {
                        text: 'Straight Bar Deadlift',
                        value: 'straight_bar_dl'
                    },
                    {
                        text: 'RFE Split Squat',
                        value: 'rfe_split_squat'
                    },
                    {
                        text: 'RFE Split Squat x 8/leg',
                        value: 'rfe_split_squat_x8'
                    }
                ],
                upperBodyPush: null,
                allUpperBodyPush: [{
                        text: 'Choose an exercise',
                        value: null
                    },
                    {
                        text: 'Bench Press',
                        value: 'bench_press'
                    },
                    {
                        text: 'Kettle Bell Overhead Press',
                        value: 'kb_oh_press'
                    },
                    {
                        text: 'DB Bench Press x 5',
                        value: 'db_bench_press'
                    },
                    {
                        text: 'Landmine Press x 5',
                        value: 'landmine_press_x5'
                    },
                    {
                        text: 'Landmine Press',
                        value: 'landmine_press'
                    }
                ],
                upperBodyPull: null,
                allUpperBodyPull: [{
                        text: 'Choose an exercise',
                        value: null
                    },
                    {
                        text: 'Weighted Pull-up',
                        value: 'pullups'
                    },
                    {
                        text: 'DB Row (10RM)',
                        value: 'db_row'
                    },
                    {
                        text: 'DB Row x 5/arm',
                        value: 'db_row_x5'
                    }
                ],
                rfe_split_squat: null,
                feetOptions: null,
                inchOptions: null,
                isValid: false
            }
        },
        computed: {
            ...mapState([
                'inputData',
                'distanceUnit',
                'weightUnit',
            ]),
        },
        mounted() {
            
            this.inchOptions = this.generateOptions(0,12);
            this.feetOptions = this.generateOptions(1,15);
            this.meterOptions = this.generateOptions(1,5);
            this.cmOptions = this.generateOptions(1,99);

            // Check what should be enabled
            forEach(this.$store.state.currentProfileConfigInputs, (key) => {
                if(has(this,key)) {
                    console.log('Enabling: '+key);
                    this[key] = true;
                } else {
                    this[key] = false;
                }
            });

            // console.log(this.$store.state.currentProfileConfigInputs)
            this.squats = this.filterChoices(this.allSquats, this.$store.state.currentProfileConfigInputs);
            this.deadlifts = this.filterChoices(this.allDeadlifts, this.$store.state.currentProfileConfigInputs);
            this.upperBodyPush = this.filterChoices(this.allUpperBodyPush, this.$store.state.currentProfileConfigInputs);
            this.upperBodyPull = this.filterChoices(this.allUpperBodyPull, this.$store.state.currentProfileConfigInputs);

        },
        watch: {
            formData: {
                deep: true,
                handler() {
                    this.isValid = (this.formData.name != null && this.formData.email != null && this.formData.weight != null && this.formData.type != null);
                    this.$emit('isValid', this.isValid);
                }
            }
        },
        methods: {
            generateOptions(min,max) {
                let temp = [];
                for(let i = min; i < max; i++) {
                    temp.push({ text: i, value: i });
                }
                return temp;
            },
            filterChoices(choices) {
                let results = filter(choices, (option) => {
                    if (this.$store.state.currentProfileConfigInputs.indexOf(option.value) > -1 || option.value == null) {
                        return option;
                    }
                })
                if(results.length == 1) {
                    return null;
                }
                return results;
            },
            onSubmit(e) {
                console.log('onSubmit');
                e.preventDefault();
                this.$store.dispatch('nextStep');
            },
            // onReset(e) {
            //     e.preventDefault();
            //     this.form = {
            //         squatType: null
            //     }
            // },
            onClickBack() {
                this.$store.dispatch('previousStep');
            },
            onClickNext(e) {
                e.preventDefault();
                this.$store.dispatch('nextStep');
            }
        }
    }
</script>