<template>
  <div id="app" class="stepper-container container-fluid">
    <Stepper />
  </div>
</template>

<script>
import Stepper from './components/Stepper.vue'

export default {
  name: 'App',
  components: {
    Stepper
  },
  mounted() {
    // Check if the values are already set
    this.$store.dispatch('getConfig');
  }
}
</script>

<style lang="scss" scoped>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 60px;
}
.logo {
  margin-bottom: 40px;
}
</style>
