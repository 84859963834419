import Intro from './components/steps/Intro.vue';
import ProfileSelect from './components/steps/ProfileSelect.vue';
import Strength from './components/steps/Strength.vue';
import WorkCapacity from './components/steps/WorkCapacity.vue';
import Conditioning from './components/steps/Conditioning.vue';
import Movement from './components/steps/Movement.vue';
import Recovery from './components/steps/Recovery.vue';
import Finished from './components/steps/Finished.vue';

export default [
    {
      path: '/',
      name: 'intro',
      component: Intro,
    },
    {
      path: '/profile-select',
      name: 'profile-select',
      component: ProfileSelect,
    },
    {
      path: '/strength',
      name: 'strength',
      component: Strength,
    },
    {
      path: '/work_capacity',
      name: 'work_capacity',
      component: WorkCapacity,
    },
    {
      path: '/conditioning',
      name: 'conditioning',
      component: Conditioning,
    },
    {
      path: '/movement',
      name: 'movement',
      component: Movement,
    },
    {
      path: '/recovery',
      name: 'recovery',
      component: Recovery,
    },
    {
      path: '/finished',
      name: 'finished',
      component: Finished,
    }
]
  