import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'

import store from './store/index.js';
import routes from './routes.js';

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-icons/font/bootstrap-icons.scss'
import './assets/_shared.scss';

Vue.use(VueRouter)
Vue.use(Vuex);
Vue.use(BootstrapVue);

Vue.config.productionTip = false

const router = new VueRouter({
  routes
})

new Vue({
  render: h => h(App),
  router: router,
  store: store,
	beforeCreate() {
		this.$store.commit('initializeStore');
	}
}).$mount('#app')