<template>
    <div class="container step-container">
        <b-form @submit="onSubmit" >
            <StepTitle title="Conditioning" />
            
            <div class="step-copy">
                <p>Conditioning tests give us and you an insight into several things:</p>

                <ul>
                    <li>How well developed your various energy systems are</li>
                    <li>How much time you’ve put into the specific modalities your career requires</li>
                    <li>How your mind deals with different types of pain and fatigue</li>
                </ul>

                <p>All of this information is useful for creating a baseline of where you are at right now, how that compares to the standards for your profession or goals, and measuring change over time.</p>
                <p>The goal isn’t to get better at the tests. But, by improving the underlying physiology and the mental skills associated with dealing with pain and fatigue, these metrics will improve as a byproduct of intelligent training over time. For example, running 1.5 miles quickly doesn’t make you good at your job, but it definitely makes it easier to pass a SOF selection, do a speedy infil with energy left for the operation, chase down and tackle a fleeing suspect, or fight a fire. </p>
                <p>Conditioning tests should be performed on separate days. Shorter tests, like a 400m sprint or 2-mile run can be completed after doing work capacity, strength, or power tests. Swim and run/ruck tests can also be performed on the same day with a short break between events.</p>
            </div>

            <span class="exercise-label">READ THE INFO BUTTONS TO MAKE SURE YOU ARE DOING THE TESTS PROPERLY!</span>

            <b-button class="center tp-btn" v-b-modal.tp-conditioning-modal variant="primary">
                Testing Protocol
            </b-button>
            
            <div class="step-inputs">
                <div class="c_8m_ruck exercise-item" v-if="c_8m_ruck">
                    <div class="row">
                        <div class="col col-md-3 col-sm-10">
                            <span class="exercise-label">8 Mile Ruck</span>
                        </div>
                        <div class="col-2">
                            <b-button type="button" class="info-btn btn-secondary" v-b-modal.c_8m_ruck-modal><i class="bi bi-info-lg"></i></b-button>
                        </div>
                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                            <div class="row">
                                <div class="col-6">
                                    <b-form-select v-model="inputData.c_8m_ruck.hours" :options="metrics.extendedCapacity.hourOptions"
                                        size="lg" required>
                                        <template #first>
                                            <b-form-select-option :value="null" disabled selected>Hours</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </div>
                                <div class="col-6">
                                    <b-form-select v-model="inputData.c_8m_ruck.minutes" :options="metrics.extendedCapacity.minuteOptions"
                                        size="lg" required>
                                        <template #first>
                                            <b-form-select-option :value="null" disabled selected>Minutes</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class="c_4m_ruck exercise-item" v-if="c_10k_row_or_ski">
                    <div class="row">
                        <div class="col-3">
                            <span class="exercise-label">10k Row</span>
                        </div>
                        <div class="col-2">
                            <b-button type="button" class="info-btn btn-secondary" v-b-modal.c_10k_row-modal><i class="bi bi-info-lg"></i></b-button>
                        </div>
                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                            <div class="row">
                                <div class="col-6">
                                    <b-form-select v-model="inputData.c_10k_row_or_ski.hours" :options="metrics.capacity.hourOptions"
                                        size="lg" required>
                                        <template #first>
                                            <b-form-select-option :value="null" disabled selected>Hours</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </div>
                                <div class="col-6">
                                    <b-form-select v-model="inputData.c_10k_row_or_ski.minutes" :options="metrics.capacity.minuteOptions"
                                        size="lg" required>
                                        <template #first>
                                            <b-form-select-option :value="null" disabled selected>Minutes</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->

                <!-- <div class="c_4m_ruck exercise-item" v-if="c_2m_run">
                    <div class="row">
                        <div class="col col-md-3 col-sm-10">
                            <span class="exercise-label">2 mile run</span>
                        </div>
                        <div class="col-2">
                            <b-button type="button" class="info-btn btn-secondary" v-b-modal.c_15m_run-modal><i class="bi bi-info-lg"></i></b-button>
                        </div>
                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                            <div class="row">
                                <div class="col-6">
                                    <b-form-select v-model="inputData.c_2m_run.minutes" :options="metrics.capacity.minuteOptions"
                                        size="lg" required>
                                        <template #first>
                                            <b-form-select-option :value="null" disabled selected>Minutes</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </div>
                                <div class="col-6">
                                    <b-form-select v-model="inputData.c_2m_run.seconds" :options="metrics.capacity.secondOptions"
                                        size="lg" required>
                                        <template #first>
                                            <b-form-select-option :value="null" disabled selected>Seconds</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->

                <!-- <div class="c_10k_row exercise-item" v-if="c_10k_row">
                    <div class="row">
                        <div class="col col-md-3 col-sm-10">
                            <span class="exercise-label">10k Row</span>
                        </div>
                        <div class="col-2">
                            <b-button type="button" class="info-btn btn-secondary" v-b-modal.c_10k_row-modal><i class="bi bi-info-lg"></i></b-button>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="row">
                                <div class="col-6">
                                    <b-form-select v-model="inputData.c_10k_row.hours" :options="metrics.capacity.hourOptions"
                                        size="lg" required>
                                        <template #first>
                                            <b-form-select-option :value="null" disabled selected>Hours</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </div>
                                <div class="col-6">
                                    <b-form-select v-model="inputData.c_10k_row.minutes" :options="metrics.capacity.minuteOptions"
                                        size="lg" required>
                                        <template #first>
                                            <b-form-select-option :value="null" disabled selected>Minutes</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->

                <!-- <div class="c_4m_ruck exercise-item" v-if="c_4m_ruck">
                    <div class="row">
                        <div class="col col-md-3 col-sm-10">
                            <span class="exercise-label">4 Mile Ruck</span>
                        </div>
                        <div class="col-2">
                            <b-button type="button" class="info-btn btn-secondary" v-b-modal.c_4m_ruck-modal><i class="bi bi-info-lg"></i></b-button>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="row">
                                <div class="col-6">
                                    <b-form-select v-model="inputData.c_4m_ruck.hours" :options="metrics.capacity.hourOptions"
                                        size="lg" required>
                                        <template #first>
                                            <b-form-select-option :value="null" disabled selected>Hours</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </div>
                                <div class="col-6">
                                    <b-form-select v-model="inputData.c_4m_ruck.minutes" :options="metrics.capacity.minuteOptions"
                                        size="lg" required>
                                        <template #first>
                                            <b-form-select-option :value="null" disabled selected>Minutes</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->

                <!-- Capacity -->
                <div class="capacity exercise-item" v-if="capacity">
                    <b-form-group label="Capacity Exercise">
                        <b-form-select v-model="inputData.capacityType" :options="capacity" size="lg" required>
                        </b-form-select>
                    </b-form-group>

                    <div class="row" v-if="inputData.capacityType != null">
                        <div class="col-lg-2 col-md-2 col-sm-2">
                            <b-button type="button" class="info-btn btn-secondary" v-b-modal.c_4m_ruck-modal v-show="inputData.capacityType == 'c_4m_ruck'"><i class="bi bi-info-lg"></i>
                            </b-button>
                            <b-button type="button" class="info-btn btn-secondary" v-b-modal.c_5m_run-modal v-show="inputData.capacityType == 'c_5m_run'"><i class="bi bi-info-lg"></i>
                            </b-button>
                            <b-button type="button" class="info-btn btn-secondary" v-b-modal.c_10k_row-modal v-show="inputData.capacityType == 'c_10k_row'"><i class="bi bi-info-lg"></i>
                            </b-button>
                            <b-button type="button" class="info-btn btn-secondary" v-b-modal.c_10k_row-modal v-show="inputData.capacityType == 'c_10k_row_or_ski'"><i class="bi bi-info-lg"></i>
                            </b-button>
                        </div>
                        <div class="col-lg-10 col-md-10 col-sm-10">
                            <div class="row">
                                <div class="col-6">
                                    <b-form-select v-model="inputData[inputData.capacityType].hours" :options="metrics.capacity.hourOptions"
                                        size="lg" required>
                                        <template #first>
                                            <b-form-select-option :value="null" disabled selected>Hours</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </div>
                                <div class="col-6">
                                    <b-form-select v-model="inputData[inputData.capacityType].minutes" :options="metrics.capacity.minuteOptions"
                                        size="lg" required>
                                        <template #first>
                                            <b-form-select-option :value="null" disabled selected>Minutes</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="c_4m_ruck exercise-item" v-if="c_3m_run">
                    <div class="row">
                        <div class="col col-md-3 col-sm-10">
                            <span class="exercise-label">3 mile run</span>
                        </div>
                        <div class="col-2">
                            <b-button type="button" class="info-btn btn-secondary" v-b-modal.c_3m_run-modal><i class="bi bi-info-lg"></i></b-button>
                        </div>
                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                            <div class="row">
                                <div class="col-6">
                                    <b-form-select v-model="inputData.c_3m_run.minutes" :options="metrics.capacity.minuteOptions"
                                        size="lg" required>
                                        <template #first>
                                            <b-form-select-option :value="null" disabled selected>Minutes</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </div>
                                <div class="col-6">
                                    <b-form-select v-model="inputData.c_3m_run.seconds" :options="metrics.capacity.secondOptions"
                                        size="lg" required>
                                        <template #first>
                                            <b-form-select-option :value="null" disabled selected>Seconds</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="c_2000m_swim exercise-item" v-if="c_2000m_swim">
                    <div class="row">
                        <div class="col col-md-3 col-sm-10">
                            <span class="exercise-label">2000m CSS</span>
                        </div>
                        <div class="col-2">
                            <b-button type="button" class="info-btn btn-secondary" v-b-modal.c_2000m_swim-modal><i class="bi bi-info-lg"></i></b-button>
                        </div>
                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                            <div class="row">
                                <div class="col-6">
                                    <b-form-select v-model="inputData.c_2000m_swim.minutes" :options="metrics.c2000m.minuteOptions" size="lg" required>
                                        <template #first>
                                            <b-form-select-option :value="null" disabled selected>Minutes</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </div>
                                <div class="col-6">
                                    <b-form-select v-model="inputData.c_2000m_swim.seconds" :options="metrics.c2000m.secondOptions" size="lg" required>
                                        <template #first>
                                            <b-form-select-option :value="null" disabled selected>Seconds</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Extended Power -->
                <div class="extended-power exercise-item" v-if="extendedPower">
                    <b-form-group label="Extended Power Exercise">
                        <b-form-select v-model="inputData.extendedPowerType" :options="extendedPower" size="lg" required>
                        </b-form-select>
                    </b-form-group>

                    <div class="row" v-if="inputData.extendedPowerType != null">
                        <div class="col-lg-2 col-md-2 col-sm-2">
                            <b-button type="button" class="info-btn btn-secondary" v-b-modal.c_2000m_row-modal v-show="inputData.extendedPowerType == 'c_2000m_row'"><i class="bi bi-info-lg"></i>
                            </b-button>
                            <b-button type="button" class="info-btn btn-secondary" v-b-modal.c_15m_run-modal v-show="inputData.extendedPowerType == 'c_15m_run'"><i class="bi bi-info-lg"></i>
                            </b-button>
                            <!-- <b-button type="button" class="info-btn btn-secondary" v-b-modal.c_2000m_row_or_ski-modal v-if="inputData.extendedPowerType == 'c_2000m_row_or_ski'"><i class="bi bi-info-lg"></i>
                            </b-button> -->
                            <b-button type="button" class="info-btn btn-secondary" v-b-modal.c_15m_run-modal v-show="inputData.extendedPowerType == 'c_2m_run'"><i class="bi bi-info-lg"></i>
                            </b-button>
                        </div>
                        <div class="col-lg-10 col-md-10 col-sm-10">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12" v-if="inputData.extendedPowerType == 'c_2000m_row'">
                                    <div class="row">
                                        <div class="col-6">
                                            <b-form-select v-model="inputData.c_2000m_row.minutes" :options="metrics.extendedPower.minuteOptions"
                                                size="lg" required>
                                                <template #first>
                                                    <b-form-select-option :value="null" disabled selected>Minutes</b-form-select-option>
                                                </template>
                                            </b-form-select>
                                        </div>
                                        <div class="col-6">
                                            <b-form-select v-model="inputData.c_2000m_row.seconds" :options="metrics.extendedPower.secondOptions"
                                                size="lg" required>
                                                <template #first>
                                                    <b-form-select-option :value="null" disabled selected>Seconds</b-form-select-option>
                                                </template>
                                            </b-form-select>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 col-sm-12" v-if="inputData.extendedPowerType == 'c_15m_run'">
                                    <div class="row">
                                        <div class="col-6">
                                            <b-form-select v-model="inputData.c_15m_run.minutes" :options="metrics.extendedPower.minuteOptions"
                                                size="lg" required>
                                                <template #first>
                                                    <b-form-select-option :value="null" disabled selected>Minutes</b-form-select-option>
                                                </template>
                                            </b-form-select>
                                        </div>
                                        <div class="col-6">
                                            <b-form-select v-model="inputData.c_15m_run.seconds" :options="metrics.extendedPower.secondOptions"
                                                size="lg" required>
                                                <template #first>
                                                    <b-form-select-option :value="null" disabled selected>Seconds</b-form-select-option>
                                                </template>
                                            </b-form-select>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" v-if="inputData.extendedPowerType == 'c_2000m_row_or_ski'">
                                    <b-form-select v-model="inputData.c_2000m_row.minutes" :options="metrics.extendedPower.minuteOptions" size="lg" required>
                                        <template #first>
                                            <b-form-select-option :value="null" disabled selected>Minutes</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </div>

                                <div class="col-lg-12 col-md-12 col-sm-12" v-if="inputData.extendedPowerType == 'c_2m_run'">
                                    <div class="row">
                                        <div class="col-6">
                                            <b-form-select v-model="inputData.c_2m_run.minutes" :options="metrics.extendedPower.minuteOptions"
                                                size="lg" required>
                                                <template #first>
                                                    <b-form-select-option :value="null" disabled selected>Minutes</b-form-select-option>
                                                </template>
                                            </b-form-select>
                                        </div>
                                        <div class="col-6">
                                            <b-form-select v-model="inputData.c_2m_run.seconds" :options="metrics.extendedPower.secondOptions"
                                                size="lg" required>
                                                <template #first>
                                                    <b-form-select-option :value="null" disabled selected>Seconds</b-form-select-option>
                                                </template>
                                            </b-form-select>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="c_500m_swim exercise-item" v-if="c_500m_swim">
                    <div class="row">
                        <div class="col col-md-3 col-sm-10">
                            <span class="exercise-label">500m CSS</span>
                        </div>
                        <div class="col-2">
                            <b-button type="button" class="info-btn btn-secondary" v-b-modal.c_500m_swim-modal><i class="bi bi-info-lg"></i></b-button>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="row">
                                <div class="col-6">
                                    <b-form-select v-model="inputData.c_500m_swim.minutes" :options="metrics.extendedPower.minuteOptions"
                                        size="lg" required>
                                        <template #first>
                                            <b-form-select-option :value="null" disabled selected>Minutes</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </div>
                                <div class="col-6">
                                    <b-form-select v-model="inputData.c_500m_swim.seconds" :options="metrics.extendedPower.secondOptions"
                                        size="lg" required>
                                        <template #first>
                                            <b-form-select-option :value="null" disabled selected>Seconds</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Power -->
                <div class="power exercise-item" v-if="power">
                    <b-form-group label="Power Exercise">
                        <b-form-select v-model="inputData.powerType" :options="power" size="lg" required>
                        </b-form-select>
                    </b-form-group>

                    <div class="row" v-if="inputData.powerType != null">
                        <div class="col-lg-2 col-md-2 col-sm-2">
                            <b-button type="button" class="info-btn btn-secondary" v-b-modal.c_400m_run-modal v-show="inputData.powerType == 'c_400m_run'"><i class="bi bi-info-lg"></i>
                            </b-button>
                            <b-button type="button" class="info-btn btn-secondary" v-b-modal.c_500m_row-modal v-show="inputData.powerType == 'c_500m_row'"><i class="bi bi-info-lg"></i>
                            </b-button>
                            <b-button type="button" class="info-btn btn-secondary" v-b-modal.c_10k_row-modal v-show="inputData.powerType == 'c_500m_row_or_ski'"><i class="bi bi-info-lg"></i>
                            </b-button>
                        </div>
                        <div class="col-lg-10 col-md-10 col-sm-10">

                            <div class="row" v-if="inputData.powerType == 'c_400m_run'">
                                <div class="col-6">
                                    <b-form-select v-model="inputData.c_400m_run.minutes" :options="metrics.power.minuteOptions"
                                        size="lg" required>
                                        <template #first>
                                            <b-form-select-option :value="null" disabled selected>Minutes</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </div>
                                <div class="col-6">
                                    <b-form-select v-model="inputData.c_400m_run.seconds" :options="metrics.power.secondOptions"
                                        size="lg" required>
                                        <template #first>
                                            <b-form-select-option :value="null" disabled selected>Seconds</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </div>
                            </div>

                            <div class="row" v-if="inputData.powerType == 'c_500m_row'">
                                <div class="col-6">
                                    <b-form-select v-model="inputData.c_500m_row.minutes" :options="metrics.power.minuteOptions"
                                        size="lg" required>
                                        <template #first>
                                            <b-form-select-option :value="null" disabled selected>Minutes</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </div>
                                <div class="col-6">
                                    <b-form-select v-model="inputData.c_500m_row.seconds" :options="metrics.power.secondOptions"
                                        size="lg" required>
                                        <template #first>
                                            <b-form-select-option :value="null" disabled selected>Seconds</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </div>
                            </div>

                            <div class="row" v-if="inputData.powerType == 'c_500m_row_or_ski'">
                                <div class="col-6">
                                    <b-form-select v-model="inputData.c_500m_row_or_ski.minutes" :options="metrics.power.minuteOptions"
                                        size="lg" required>
                                        <template #first>
                                            <b-form-select-option :value="null" disabled selected>Minutes</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </div>
                                <div class="col-6">
                                    <b-form-select v-model="inputData.c_500m_row_or_ski.seconds" :options="metrics.power.secondOptions"
                                        size="lg" required>
                                        <template #first>
                                            <b-form-select-option :value="null" disabled selected>Seconds</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                

                <!-- <div class="c_5m_run exercise-item" v-if="c_5m_run">
                    <div class="row">
                        <div class="col col-md-3 col-sm-10">
                            <span class="exercise-label">5 Mile Run</span>
                        </div>
                        <div class="col-2">
                            <b-button type="button" class="info-btn btn-secondary" v-b-modal.c_5m_run-modal><i class="bi bi-info-lg"></i></b-button>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="row">
                                <div class="col-6">
                                    <b-form-select v-model="inputData.c_5m_run.hours" :options="capacity.hourOptions"
                                        size="lg" required>
                                        <template #first>
                                            <b-form-select-option :value="null" disabled selected>Hours</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </div>
                                <div class="col-6">
                                    <b-form-select v-model="inputData.c_5m_run.minutes" :options="capacity.minuteOptions"
                                        size="lg" required>
                                        <template #first>
                                            <b-form-select-option :value="null" disabled selected>Minutes</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->

                <!-- <div class="c_15m_run exercise-item" v-if="c_15m_run">
                    <div class="row">
                        <div class="col col-md-3 col-sm-10">
                            <span class="exercise-label">1.5 Mile Run</span>
                        </div>
                        <div class="col-2">
                            <b-button type="button" class="info-btn btn-secondary" v-b-modal.c_15m_run-modal><i class="bi bi-info-lg"></i></b-button>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="row">
                                <div class="col-6">
                                    <b-form-select v-model="inputData.c_15m_run.minutes" :options="metrics.extendedPower.minuteOptions"
                                        size="lg" required>
                                        <template #first>
                                            <b-form-select-option :value="null" disabled selected>Minutes</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </div>
                                <div class="col-6">
                                    <b-form-select v-model="inputData.c_15m_run.seconds" :options="metrics.extendedPower.secondOptions"
                                        size="lg" required>
                                        <template #first>
                                            <b-form-select-option :value="null" disabled selected>Seconds</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->

                <!-- <div class="c_400m_run exercise-item" v-if="c_400m_run">
                    <div class="row">
                        <div class="col col-md-3 col-sm-10">
                            <span class="exercise-label">400m Run</span>
                        </div>
                        <div class="col-2">
                            <b-button type="button" class="info-btn btn-secondary" v-b-modal.c_400m_run-modal><i class="bi bi-info-lg"></i></b-button>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div class="row">
                                <div class="col-6">
                                    <b-form-select v-model="inputData.c_400m_run.minutes" :options="metrics.power.minuteOptions"
                                        size="lg" required>
                                        <template #first>
                                            <b-form-select-option :value="null" disabled selected>Minutes</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </div>
                                <div class="col-6">
                                    <b-form-select v-model="inputData.c_400m_run.seconds" :options="metrics.power.secondOptions"
                                        size="lg" required>
                                        <template #first>
                                            <b-form-select-option :value="null" disabled selected>Seconds</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->

                <!-- <div class="c_2000m_row exercise-item" v-if="c_2000m_row">
                    <div class="row">
                        <div class="col col-md-3 col-sm-10">
                            <span class="exercise-label">2000m Row</span>
                        </div>
                        <div class="col-2">
                            <b-button type="button" class="info-btn btn-secondary" v-b-modal.c_2000m_row-modal><i class="bi bi-info-lg"></i></b-button>
                        </div>
                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                            <b-form-select v-model="inputData.c_2000m_row.minutes" :options="metrics.extendedPower.minuteOptions" size="lg" required>
                                <template #first>
                                    <b-form-select-option :value="null" disabled selected>Minutes</b-form-select-option>
                                </template>
                            </b-form-select>
                        </div>
                    </div>
                </div> -->

                <!-- <div class="c_500m_row exercise-item" v-if="c_500m_row">
                    <div class="row">
                        <div class="col-3">
                            <span class="exercise-label">500m Row</span>
                        </div>
                        <div class="col-2">
                            <b-button type="button" class="info-btn btn-secondary" v-b-modal.c_500m_row-modal><i class="bi bi-info-lg"></i></b-button>
                        </div>
                        <div class="col-6">
                            <div class="row">
                                <div class="col-6">
                                    <b-form-select v-model="inputData.c_500m_row.minutes" :options="metrics.power.minuteOptions"
                                        size="lg" required>
                                        <template #first>
                                            <b-form-select-option :value="null" disabled selected>Minutes</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </div>
                                <div class="col-6">
                                    <b-form-select v-model="inputData.c_500m_row.seconds" :options="metrics.power.secondOptions"
                                        size="lg" required>
                                        <template #first>
                                            <b-form-select-option :value="null" disabled selected>Seconds</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->

                <!-- <div class="c_500m_row exercise-item" v-if="c_500m_row_or_ski">
                    <div class="row">
                        <div class="col col-md-3 col-sm-10">
                            <span class="exercise-label">500m Row or Ski</span>
                        </div>
                        <div class="col-2">
                            <b-button type="button" class="info-btn btn-secondary" v-b-modal.c_500m_row-modal><i class="bi bi-info-lg"></i></b-button>
                        </div>
                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                            <div class="row">
                                <div class="col-6">
                                    <b-form-select v-model="inputData.c_500m_row_or_ski.minutes" :options="metrics.power.minuteOptions"
                                        size="lg" required>
                                        <template #first>
                                            <b-form-select-option :value="null" disabled selected>Minutes</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </div>
                                <div class="col-6">
                                    <b-form-select v-model="inputData.c_500m_row_or_ski.seconds" :options="metrics.power.secondOptions"
                                        size="lg" required>
                                        <template #first>
                                            <b-form-select-option :value="null" disabled selected>Seconds</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>-->
            </div> 

            <!-- Modals -->
            <b-modal id="tp-conditioning-modal" title="Testing Protocol" hide-footer size="xl" scrollable>
                <TPConditioningModal />
            </b-modal>
            <b-modal id="c_8m_ruck-modal" title="8 Mile Ruck" hide-footer size="xl" scrollable>
                <C8MileRuckModal />
            </b-modal>
            <b-modal id="c_4m_ruck-modal" title="4 Mile Ruck" hide-footer size="xl" scrollable>
                <C4MileRuckModal />
            </b-modal>
            <b-modal id="c_10k_row-modal" title="10k Row" hide-footer size="xl" scrollable>
                <C10KRowModal />
            </b-modal>
            <b-modal id="c_5m_run-modal" title="5 Mile Run" hide-footer size="xl" scrollable>
                <C5MileRunModal />
            </b-modal>
            <b-modal id="c_15m_run-modal" title="1.5 - 2 Mile Run" hide-footer size="xl" scrollable>
                <C15MRunModal />
            </b-modal>
            <b-modal id="c_3m_run-modal" title="3 Mile Run" hide-footer size="xl" scrollable>
                <C3MileRunModal />
            </b-modal>
            <b-modal id="c_2000m_swim-modal" title="2000 Meter CSS" hide-footer size="xl" scrollable>
                <C2000MSwimModal />
            </b-modal>
            <b-modal id="c_500m_swim-modal" title="500 Mile CSS" hide-footer size="xl" scrollable>
                <C500MSwimModal />
            </b-modal>
            <b-modal id="c_2000m_row-modal" title="2000 Meter Row" hide-footer size="xl" scrollable>
                <C2000MRowModal />
            </b-modal>
            <b-modal id="c_400m_run-modal" title="400 Meter Run" hide-footer size="xl" scrollable>
                <C400MRunModal />
            </b-modal>
            <b-modal id="c_500m_row-modal" title="500m row" hide-footer size="xl" scrollable>
                <C500MRowModal />
            </b-modal>
            

            <div class="step-controls row">
                <div class="col-12">
                    <b-button class="float-left" variant="secondary" size="lg" @click="onClickBack">Back</b-button>
                    <b-button type="submit" class="float-right" variant="primary" size="lg" >Next</b-button>
                </div>
            </div>

        </b-form>
        
    </div>

</template>

<style lang="scss" scoped>
.info-btn {
    margin-bottom: 10px;
}
</style>

<script>
import {
    mapState
} from 'vuex'

import StepTitle from '../StepTitle.vue';

import TPConditioningModal from '../modals/tp-conditioning-modal.vue'
import C8MileRuckModal from '../modals/c-8m-ruck-modal.vue'
import C4MileRuckModal from '../modals/c-4m-ruck-modal.vue'
import C10KRowModal from '../modals/c-10k-row-modal.vue'
import C5MileRunModal from '../modals/c-5m-run-modal.vue'
import C15MRunModal from '../modals/c-15m-run-modal.vue'
import C500MSwimModal from '../modals/c-500m-swim-modal.vue'
import C2000MSwimModal from '../modals/c-2000m-swim-modal.vue'
import C2000MRowModal from '../modals/c-2000m-row-modal.vue'
import C400MRunModal from '../modals/c-400m-run-modal.vue'
import C500MRowModal from '../modals/c-500m-row-modal.vue'
import C3MileRunModal from '../modals/c-3m-run-modal.vue';
// import GobletSquatModal from '../modals/goblet-squat-modal.vue'
// import DeadliftModal from '../modals/deadlift-modal.vue'
// import PressModal from '../modals/press-modal.vue'

import {forEach,has,filter} from 'lodash';

export default {
    components: {
        StepTitle,
        TPConditioningModal,
        C8MileRuckModal,
        C5MileRunModal,
        C4MileRuckModal,
        C10KRowModal,
        C15MRunModal,
        C500MSwimModal,
        C2000MSwimModal,
        C2000MRowModal,
        C400MRunModal,
        C500MRowModal,
        C3MileRunModal
    },
    data() {
      return {
        c_8m_ruck: null,
        c_10k_row_or_ski: null,
        c_3m_run: null,
        c_2m_run: null,
        c_10k_row: null,
        c_4m_ruck: null,
        c_5m_run: null,
        c_15m_run: null,
        c_400m_run: null,
        c_2000m_row: null,
        c_2000m_swim: null,
        c_500m_swim: null,
        c_500m_row: null,
        c_500m_row_or_ski: null,

        capacity: null,
        extendedPower: null,
        power: null,

        allCapacityOptions: [{
                text: 'Choose an exercise',
                value: null
            },
            {
                text: '4 Mile Ruck',
                value: 'c_4m_ruck'
            },
            {
                text: '5 Mile Run',
                value: 'c_5m_run'
            },
            {
                text: '10k Row',
                value: 'c_10k_row'
            },
            {
                text: '10k Row Or Ski',
                value: 'c_10k_row_or_ski'
            }
        ],
        extendedPowerOptions: [
            {
                text: 'Choose an exercise',
                value: null
            },
            {
                text: '2000m row',
                value: 'c_2000m_row'
            },
            {
                text: '1.5 Mile Run',
                value: 'c_15m_run'
            },
            {
                text: '2000m Row Or Ski',
                value: 'c_2000m_row_or_ski'
            },
            {
                text: '2 Mile Run',
                value: 'c_2m_run'
            }
        ],
        powerOptions: [
            {
                text: 'Choose an exercise',
                value: null
            },
            {
                text: '400m Run',
                value: 'c_400m_run'
            },
            {
                text: '500m Row',
                value: 'c_500m_row'
            },
            {
                text: '500m Row or Ski',
                value: 'c_500m_row_or_ski'
            }
        ],
        metrics: {
            extendedCapacity: {
                hourOptions: null,
                minuteOptions: null
            },
            capacity: {
                hourOptions: null,
                minuteOptions: null,
                secondOptions: null
            },
            extendedPower: {
                minuteOptions: null,
                secondOptions: null
            },
            c2000m: {
                minuteOptions: null,
                secondOptions: null
            },
            power: {
                minuteOptions: null,
                secondOptions: null
            }
        }
      }
    },
    computed: {
        ...mapState([
            'inputData'
        ]),
    },
    mounted() {
        this.metrics.extendedCapacity.hourOptions = this.generateOptions(0, 4);
        this.metrics.extendedCapacity.minuteOptions = this.generateOptions(0, 59);

        this.metrics.capacity.hourOptions = this.generateOptions(0, 1);
        this.metrics.capacity.minuteOptions = this.generateOptions(0, 59);
        this.metrics.capacity.secondOptions = this.generateOptions(0, 59);

        this.metrics.extendedPower.minuteOptions = this.generateOptions(5, 30);
        this.metrics.extendedPower.secondOptions = this.generateOptions(0, 59);

        this.metrics.power.minuteOptions = this.generateOptions(0, 10);
        this.metrics.power.secondOptions = this.generateOptions(0, 59);

        this.metrics.c2000m.minuteOptions = this.generateOptions(5, 60);
        this.metrics.c2000m.secondOptions = this.generateOptions(0, 59);

        this.capacity = this.filterChoices(this.allCapacityOptions, this.$store.state.currentProfileConfigInputs);
        this.extendedPower = this.filterChoices(this.extendedPowerOptions, this.$store.state.currentProfileConfigInputs);
        this.power = this.filterChoices(this.powerOptions, this.$store.state.currentProfileConfigInputs);

        // Check what should be enabled
        forEach(this.$store.state.currentProfileConfigInputs, (key) => {
            if(has(this,key)) {
                console.log('Enabling: '+key);
                this[key] = true;
            }
        });

    },
    methods: {
        generateOptions(min, max) {
            let temp = [];
            for (let i = min; i <= max; i++) {
                temp.push({
                    text: i,
                    value: i
                });
            }
            return temp;
        },
        filterChoices(choices) {
            let results = filter(choices, (option) => {
                if (this.$store.state.currentProfileConfigInputs.indexOf(option.value) > -1 || option.value == null) {
                    return option;
                }
            })
            if(results.length == 1) {
                return null;
            }
            return results;
        },
        onSubmit(e) {
            e.preventDefault();
            this.$store.dispatch('nextStep');
        },
        onClickBack() {
            this.$store.dispatch('previousStep');
        }
    }
}
</script>