<template>
    <div class="container step-container">
        <b-form @submit="onSubmit">
            <StepTitle title="Recovery" />
            
            <div class="step-copy">
                <p>Your body adapts much faster to training when it has the necessary resources to recover quickly. As you age, your recovery timelines naturally get a bit longer. Combined with life becoming more complicated and mounting responsibilities, recovery becomes harder to prioritize, and sleep, stress management, and food prep are often neglected. This is why under-recovery is often the biggest limiting factor for adults thirty and older.</p>
                <p>However, just because life is more complicated doesn’t mean you can’t improve your recovery practices. First, you have to be honest with yourself about how consistent you are with your recovery practices. Not being able to sleep in on weekends doesn’t mean you can make significant improvements in your sleep quality or average quantity over time. The same is true for nutrition and stress management. </p>
                <p>Nutrition can seem confusing, but some simple rules are reliable. “Enough food” means that you’re not losing or gaining weight in a way you don’t want, you feel recovered, and your sleep, energy, and hormones seem stable. So, if you’re a female athlete, your monthly cycle is consistent. “High-quality foods” mean minimally-processed whole foods with healthy fats, enough carbs to support your training and recovery, adequate protein with every meal, plenty of fruits and vegetables, and staying well-hydrated. It also means minimal refined sugar and liquid calories (sodas, alcohol, etc).</p>
                <p>Be honest with yourself when answering the questions below and use the information to make informed decisions about where your limited time and energy is best spent. Trying to train harder or do more is a path to disaster if you’re not getting everything you can out of the work you’re already doing.</p>
            </div>
            <div class="step-inputs">

                <div class="sleep_1 exercise-item" >
                    <div class="row" >
                        <div class="col-12">
                            <span class="exercise-label">How many hours of sleep do you get on average?</span>
                        </div>
                    </div>
                    <div class="row mt1" >
                        <div class="col-10">
                            <div class="row" >
                                <div class="col-xs-12 col-sm-12 col-md-8">
                                    <b-form-select v-model="sleep_1" :options="sleep1Options" size="lg" required @change="onPointChange('sleep_1')">
                                        <template #first>
                                            <b-form-select-option :value="null" disabled selected>Hours Of Sleep</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="sleep_2 exercise-item" >
                    <div class="row" >
                        <div class="col-12">
                            <span class="exercise-label">How would you rank the average quality of your sleep?</span>
                        </div>
                    </div>
                    <div class="row mt1" >
                        <div class="col-10">
                            <div class="row" >
                                <div class="col-xs-12 col-sm-12 col-md-8">
                                    <b-form-select v-model="sleep_2" :options="sleep2Options" size="lg" required @change="onPointChange('sleep_2')">
                                        <template #first>
                                            <b-form-select-option :value="null" disabled selected>Quality Of Sleep</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="stress_1 exercise-item" >
                    <div class="row" >
                        <div class="col-12">
                            <span class="exercise-label">What is your normal mood?</span>
                        </div>
                    </div>
                    <div class="row mt1" >
                        <div class="col-10">
                            <div class="row" >
                                <div class="col-xs-12 col-sm-12 col-md-8">
                                    <b-form-select v-model="stress_1" :options="stress1Options" size="lg" required @change="onPointChange('stress_1')">
                                        <template #first>
                                            <b-form-select-option :value="null" disabled selected>Mood</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="stress_2 exercise-item" >
                    <div class="row" >
                        <div class="col-12">
                            <span class="exercise-label">What is your average stress level?</span>
                        </div>
                    </div>
                    <div class="row mt1" >
                        <div class="col-10">
                            <div class="row" >
                                <div class="col-xs-12 col-sm-12 col-md-8">
                                    <b-form-select v-model="stress_2" :options="stress2Options" size="lg" required @change="onPointChange('stress_2')">
                                        <template #first>
                                            <b-form-select-option :value="null" disabled selected>Stress Level</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="nutrition_1 exercise-item" >
                    <div class="row" >
                        <div class="col-12">
                            <span class="exercise-label">How does your body feel most days?</span>
                        </div>
                    </div>
                    <div class="row mt1" >
                        <div class="col-10">
                            <div class="row" >
                                <div class="col-xs-12 col-sm-12 col-md-8">
                                    <b-form-select v-model="nutrition_1" :options="nutrition1Options" size="lg" required @change="onPointChange('nutrition_1')">
                                        <template #first>
                                            <b-form-select-option :value="null" disabled selected>Body Feel</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="nutrition_2 exercise-item" >
                    <div class="row" >
                        <div class="col-12">
                            <span class="exercise-label">What does your diet typically look like?</span>
                        </div>
                    </div>
                    <div class="row mt1" >
                        <div class="col-10">
                            <div class="row" >
                                <div class="col-xs-12 col-sm-12 col-md-8">
                                    <b-form-select v-model="nutrition_2" :options="nutrition2Options" size="lg" required @change="onPointChange('nutrition_2')">
                                        <template #first>
                                            <b-form-select-option :value="null" disabled selected>Diet</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="step-controls row">
                <div class="col-12">
                    <b-button class="float-left" variant="secondary" size="lg" @click="onClickBack">Back</b-button>
                    <b-button type="submit" class="float-right" variant="primary" size="lg" >Finish</b-button>
                </div>
            </div>

        </b-form>
        
    </div>

</template>

<style lang="scss" scoped>

</style>

<script>
import {
    mapState
} from 'vuex'

import StepTitle from '../StepTitle.vue';

import {get,forEach,has} from 'lodash';

export default {
    components: {
        StepTitle
    },
    data() {
      return {
        sleep_1: null,
        sleep_2: null,
        stress_1: null,
        stress_2: null,
        nutrition_1: null,
        nutrition_2: null,
        sleep1Options: [
            {
                text: '5 or less',
                value: '5 or less'
            },
            {
                text: '5-6',
                value: '5-6'
            },
            {
                text: '6-7',
                value: '6-7',
            },
            {
                text: '7-8',
                value: '7-8',
            },
            {
                text: '8+',
                value: '8+'
            }
        ],
        sleep2Options: [
            {
                text: 'Awful',
                value: 'awful'
            },
            {
                text: 'Poor',
                value: 'poor'
            },
            {
                text: 'Ok',
                value: 'ok',
            },
            {
                text: 'Good',
                value: 'good',
            },
            {
                text: 'Excellent',
                value: 'excellent'
            }
        ],
        stress1Options: [
            {
                text: 'Irritable',
                value: 'irritable'
            },
            {
                text: 'Touchy, moody',
                value: 'a little off'
            },
            {
                text: 'OK',
                value: 'ok'
            },
            {
                text: 'Good',
                value: 'good'
            },
            {
                text: 'Great',
                value: 'great'
            }
        ],
        stress2Options: [
            {
                text: 'Very high',
                value: 'very high'
            },
            {
                text: 'High',
                value: 'high'
            },
            {
                text: 'Moderate',
                value: 'moderate'
            },
            {
                text: 'Low',
                value: 'low'
            },
            {
                text: 'Very low',
                value: 'very low'
            }
        ],
        nutrition1Options: [
            {
                text: 'Very sore & achy',
                value: 'very sore & achy'
            },
            {
                text: 'A little beat up',
                value: 'a little beat up'
            },
            {
                text: 'Ok',
                value: 'ok'
            },
            {
                text: 'Ready to go',
                value: 'ready to go'
            },
            {
                text: 'Amazing',
                value: 'amazing'
            }
        ],
        nutrition2Options: [
            {
                text: 'Miss meals and eat low-quality foods',
                value: 'miss meals and eat low quality foods'
            },
            {
                text: 'Miss meals but eat high-quality food foods',
                value: 'miss meals but eat high quality foods'
            },
            {
                text: 'Eat enough food, a mix of high and low-quality foods',
                value: 'eat enough food - a mix of high and low quality foods'
            },
            {
                text: 'Eat enough food, mostly high quality',
                value: 'eat enough food - mostly high quality'
            },
            {
                text: 'Eat enough food, all of it is planned, high-quality foods',
                value: 'eat enough food - all of it is planned - high quality foods'
            }
        ]
      }
    },
    computed: {
        ...mapState([
            'inputData'
        ]),
    },
    mounted() {
        // Load values from inputData in state
        let list = ['sleep_1', 'sleep_2', 'stress_1', 'stress_2','nutrition_1','nutrition_2'];
        forEach(list, (key) => {
            console.log(key);
            if(has(this,key)) {
                this[key] = get(this.$store.state.inputData,(key+'.val'));
            }
        });

    },
    methods: {
        onSubmit(e) {
            e.preventDefault();
            this.$store.dispatch('nextStep');
        },
        onClickBack() {
            this.$store.dispatch('previousStep');
        },
        onPointChange(path) {
            console.log('onPointChange');
            let val = get(this, path);
            let key = path+'.val';
            console.log({
                'path': path,
                'key': key
            })
            this.$store.dispatch('updateInputData', {
                key: key,
                val: val
            });
        }
    }
}
</script>