<template>
  <div class="container step-container">
    <b-form @submit="onSubmit" v-if="show">
      <StepTitle title="BTE Profile Generator" />
      <div>
          <div class="row">
            <div class="col-12">
              <b-form-input size="lg"  id="name" class="input-field" v-model="formData.name" placeholder="Name" required></b-form-input>
            </div>
            <div class="col-12">
              <b-form-input size="lg"  id="email" class="input-field" v-model="formData.email" type="email" placeholder="Email" required>
              </b-form-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-5 col-sm-12 v-center-wrapper unit-preference-label">
              <h3 class="v-center">Unit Preference</h3>
            </div>
            <div class="col-md-7 col-sm-12 v-center-wrapper">
              <div class="v-center">
                  <b-form-radio-group v-model="formData.unit" size="lg" required>
                    <b-form-radio class="radio" name="unit" value="imperial" @change="onChangeUnitPreference">Imperial (pounds, feet, etc.)</b-form-radio>
                    <b-form-radio size="lg" class="radio" name="unit" value="metric" @change="onChangeUnitPreference">Metric (kilograms, meters, etc.)</b-form-radio>
                </b-form-radio-group>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12  mt4">
              <b-form-input v-if="weightUnit == 'lbs'" size="lg" min="80" max="500" id="weight" class="input-field" v-model="formData.weight" type="number" :placeholder="'Weight (lbs)'" required></b-form-input>
              <b-form-input v-if="weightUnit == 'kg'" size="lg" min="40" max="500" id="weight" class="input-field" v-model="formData.weight" type="number" :placeholder="'Weight (kg)'" required></b-form-input>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <b-form-radio-group v-model="formData.type" size="lg" class="mt4" required >
                <b-form-radio class="radio" name="type" value="sof-land">SOF Land Based Selection Prep - Contract Phase
                </b-form-radio>
                <p>Select this if you have a contract or are routinely running 5+ miles and rucking 10+ miles without issues.</p>

                <b-form-radio class="radio" name="type" value="land-prep">SOF Land-Based Prep - Prep Phase
                </b-form-radio>
                <p>Select this if you are just beginning the process of preparing for a land-based selection process. This means that you haven’t routinely been running long distances (5+ miles) or rucking 8+ miles. </p>

                <b-form-radio class="radio" name="type" value="sof-maritime">SOF Maritime Selection Prep - Contract Phase
                </b-form-radio>
                <p>Select this if you have a contract or are routinely running 5+ miles and swimming 2000m continuously. 
                </p>

                <b-form-radio class="radio" name="type" value="maritime-prep">SOF Maritime Selection Prep - Prep Phase
                </b-form-radio>
                <p>Select this if you are just beginning the process of preparing for a maritime selection process. This means that you haven’t routinely been running 5+ miles or swimming 2000m continuously. </p>

                <b-form-radio class="radio" name="type" value="hrt">FBI HRT Selection Prep</b-form-radio>
                <p>Select this if you’re preparing for the FBI HRT selection process and have been routinely running up to 5 miles and rucking 6+ miles. </p>

                <b-form-radio class="radio" name="type" value="operator-short">Current Operator - Short Infil</b-form-radio>
                <p>You are currently an operator looking to improve fitness across the board, stay healthy, and perform well when operational. Your job doesn’t require an infil beyond 60 minutes of continuous effort.</p>

                <b-form-radio class="radio" name="type" value="operator-long">Current Operator - Long Infil </b-form-radio>
                <p>You are currently an operator looking to improve fitness across the board, stay healthy, and perform well when operational. Your job requires the ability to complete a 90+ minutes infil. </p>

                <b-form-radio class="radio" name="type" value="leo">Law Enforcement Officer</b-form-radio>
                <p>You work in law enforcement (police, sheriff, homeland security, FBI, etc) or are preparing to work in this field. </p>

                <b-form-radio class="radio" name="type" value="fire-urban">Firefighter - Urban</b-form-radio>
                <p>You work as a firefighter in an urban setting or are preparing to work in this field. </p>

                <b-form-radio class="radio" name="type" value="fire-wildland">Firefighter - Wildland</b-form-radio>
                <p>You work as a wildland firefighter or are preparing to work in this field.</p>

                <b-form-radio class="radio" name="type" value="resilience">Civilian *</b-form-radio>
                <p>Your job doesn’t rely on your physical performance, or if it does, it falls outside the categories listed. Regardless of your occupation, you want to be physically capable and resilient.</p>

              </b-form-radio-group>
            </div>
          </div>

        <p>*If you’re a civilian who trains regularly, and has a high level of fitness, and want to see how you compare to the physical performance profile of a special operator? Choose one of the SOF options above. </p>

      </div>
      <div class="step-controls row">
        <div class="col-12">
            <b-button class="float-left" variant="secondary" size="lg" @click="onClickBack">Back</b-button>
            <b-button type="submit" class="float-right" variant="primary" size="lg" :disabled="!isValid" >Next</b-button>
        </div>
      </div>
    </b-form>
  </div>
</template>

<style lang="scss">
  form {
    max-width: 80%;
    margin: auto;

    .input-field {
      max-width: 300px;
      margin: auto;
      margin-bottom: 20px;
    }

    p {
      margin-left: 50px;
    }
  }

  .custom-control-label {
    font-weight: bold;
    padding-left: 5px;
    // margin-bottom: 20px;
    // margin-top: 20px;
  }
  @media (max-width: 768px) {
    div.v-center-wrapper.unit-preference-label {
      text-align: center;
      display: block;
      h3 {
        display: block;
      }
    }
  }
</style>

<script>
  import {
      mapState
  } from 'vuex'
  import StepTitle from '../StepTitle.vue';

  export default {
    components: {
      StepTitle
    },
    data() {
      return {
        isValid: false,
        // form: {
        //   // email: 'test@gmail.com',
        //   email: null,
        //   // name: 'Test 2',
        //   name: null,
        //   // weight: 200,
        //   weight: null,
        //   unit: 'imperial',
        //   type: null
        //   // type: 'sof-land'
        // },
        show: true
      }
    },
    computed: {
      ...mapState([
          'formData',
          'weightUnit'
      ]),
    },
    mounted() {
      if(this.$store.state.weightUnit == null) {
        this.$store.dispatch('setUnitPreference', this.formData.unit);
      }
      this.isValid = (this.formData.name != null && this.formData.email != null && this.formData.weight != null && this.formData.type != null);
    },
    watch: {
      formData: {
        // This will let Vue know to look inside the array
        deep: true,

        // We have to move our method to a handler field
        handler() {
          console.log(this.formData);
          this.isValid = (this.formData.name != null && this.formData.email != null && this.formData.weight != null && this.formData.type != null);
          // this.$emit('isValid', this.isValid);

        }
      }
    },
    methods: {
      onSubmit(e) {
        e.preventDefault();
        this.$store.dispatch('setCurrentProfile', this.formData.type);
        this.$store.dispatch('nextStep');
      },
      // onReset(e) {
      //   e.preventDefault()
      //   // Reset our form values
      //   this.formData.email = ''
      //   this.formData.name = ''
      //   // Trick to reset/clear native browser form validation state
      //   this.show = false
      //   this.$nextTick(() => {
      //     this.show = true
      //   })
      // },
      onChangeUnitPreference(val) {
        console.log('onChangeUnitPreference');
        console.log(val);
        this.$store.dispatch('setUnitPreference', val);
      },
      onClickBack() {
          this.$store.dispatch('previousStep');
      },
      // onClickNext() {
      //   // this.$store.dispatch('setUserData', {
      //   //   email: this.formData.email,
      //   //   name: this.formData.name,
      //   //   weight: this.formData.weight,
      //   //   unit: this.formData.unit,
      //   // });

      //   // this.$store.dispatch('setCurrentProfile', this.$refs.profileSelect.form.type);
      //   this.$store.dispatch('setCurrentProfile', this.formData.type);
      //   this.$store.dispatch('nextStep');
      // }
    }
  }
</script>
