<template>
    <div class="step-title">
        <div class="step-top-border"></div>
        <svg width="452" height="97" viewBox="0 0 452 97" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_0_34" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="212" y="0" width="20" height="28">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M212 0H232V28H212V0Z" fill="white"/>
            </mask>
            <g mask="url(#mask0_0_34)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M222.018 -0.000762939L222 2.59951L221.982 -0.000762939C219.602 10.0237 204.418 16.6295 216.811 24.4007C216.811 24.4007 217.303 19.2718 219.847 18.5715L219.109 27.6374C219.109 27.6374 220.665 27.8537 221.772 27.9611V28C221.844 27.9953 221.919 27.9891 222 27.9829C222.079 27.9891 222.156 27.9953 222.228 28L222.227 27.9611C223.335 27.8537 224.891 27.6374 224.891 27.6374L224.151 18.5715C226.696 19.2718 227.189 24.4007 227.189 24.4007C239.582 16.6295 224.398 10.0237 222.018 -0.000762939Z" fill="#FFE500"/>
            </g>
        </svg>
        <div class="step-title-text"><h1>{{title}}</h1></div>
        <div class="step-bottom-border"></div>
    </div>
</template>

<style lang="scss">
    svg {
        margin-top: -14px;
    }
    .step-title {
        position: relative;
        margin-bottom: 40px;
        margin-top: -20px;
    }
    .step-top-border {
        max-width: 452px;
        width: 100%;
        margin: auto;
        border-top: 1px solid #FFE500;
    }
    .step-bottom-border {
        max-width: 452px;
        width: 100%;
        margin: auto;
        border-bottom: 1px solid #FFE500;
    }
    .step-title-text {
        margin-top: -50px;
        left: 0px;
        width: 100%;
        text-align: center;
    }
    svg {
        width: 100%;
    }
</style>

<script>
export default {
  props: ['title'],
}
</script>

