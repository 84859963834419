<template>
    <div class="container step-container">
        <StepTitle title="Before you begin" />
        
        <div class="step-copy">
            <p> Welcome to the BTE Profile Generator.</p>
            
            <p>This tool is your first step in creating an effective program. It provides you with a comprehensive physiological profile that you can use to identify your personal strengths and weaknesses. </p>
 
            <p><a href="https://www.buildingtheelite.com/average-fails-everyone/">There is no such thing as an "average" program that works for an individual person. </a>The destination may be the same, but each person must take a unique path to get there. This profile helps you to understand what <i>your</i> path will look like, which is a crucial piece of an effective training process.</p>

            <p class="tl"><b>We have profiles for the following goals and jobs:</b></p>

            <ul class="list">
                <li>Special Operations Land-Based Selection Prep (RASP, SFAS, SAS, and similar)</li>
                <li>Special Operations Maritime Selection Prep (BUD/S, AFSW, Marine Recon, and similar)</li>
                <li>FBI HRT Selection Prep </li>
                <li>Operator (short and long infil requirements) </li>
                <li>Law Enforcement </li>
                <li>Fire Fighters (Urban and Wildland)</li>
                <li>Civilian (more info on the next page)</li>
            </ul>

            <p class="tl"><b>How it works:</b></p>

            <p>You input data from a variety of different physical tests and we generate a custom report to help you decide what to emphasize in your training and how to do it.</p>

            <p>Regardless of which profile you’ll be completing, you’ll need the data for each of the five steps of the profile generation process:</p>
            
            <ul class="list">
                <li><b>Strength tests</b></li>
                <ul>
                    <li>Four tests: two lower and two upper body movements.</li>
                </ul>
                <li><b>Conditioning tests</b></li>
                    <ul><li>3-4 tests (depends on the profile) varying from 60 seconds to two hours in length</li></ul>
                <li><b>Work Capacity tests</b></li>
                    <ul><li>3-6 tests. Things like max rep push ups, hang, carries, pull-ups, etc. </li></ul>
                <li><b>Movement tests &amp; questionnaire</b></li>
                    <ul><li>Three movement tests and a series of questions</li></ul>
                <li><b>Recovery questionnaire:</b></li>
                    <ul><li>A series of questions based on your sleep, stress management, and nutrition habits</li></ul>
            </ul>

            <p>After selecting a profile you’ll receive instructions for completing the required tests so you can gather data and come back and complete your report. You’ll also find instructions on how to execute each test properly. </p>
        </div>
        <div class="step-controls">
            <div class="col-12">
                <b-button class="center" variant="primary" size="lg" @click="onClickNext">Get Started</b-button>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.list {
    margin: auto;
    margin-bottom: 50px;
    // text-align: left;
    text-align: left;
}
@media (max-width: 440px) {
    .step-container .step-copy ul {
        margin-left: 0px;
    }
}
</style>

<script>
import StepTitle from '../StepTitle.vue';

export default {
    components: {
        StepTitle
    },
    methods: {
        onClickNext() {
            this.$store.dispatch('nextStep');
        }
    }
}
</script>
