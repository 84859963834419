<template>
    <div class="container step-container">
        <b-form @submit="onSubmit">
            <StepTitle title="Work Capacity" />

            <div class="step-copy">
                <p>Work capacity is a blend of power and capacity.</p>

                <ul>
                    <li>Power = the amount of force you can express in a short, fixed amount of time such as a max power
                        clean or broad jump</li>
                    <li>Capacity = how much force you express repeatedly over a long period of time, such as your
                        10-mile run time </li>
                </ul>

                <p>Measuring your work capacity gives us a good idea of how capable you are of doing moderate-intensity
                    work in general movements like a pushup or weighted carry repeatedly. By assessing this capability
                    you can develop a better understanding of how to build both the power and capacity aspects of this
                    domain and improve areas of specific fitness that are often underdeveloped (such as grip strength
                    and endurance) but are necessary for military, fire, and law enforcement careers.</p>

                <p>You can do all the work capacity tests on the same day if you'd like. Read the instructions before
                    completing the test and make sure you don’t cheat on quality or range of motion.</p>
            </div>

            <span class="exercise-label">READ THE INFO BUTTONS TO MAKE SURE YOU ARE DOING THE TESTS PROPERLY!</span>

            <b-button class="center tp-btn" v-b-modal.tp-work-capacity-modal variant="primary">
                Testing Protocol
            </b-button>

            <div class="step-inputs">
                <div class="pushups exercise-item" v-if="pushups">
                    <div class="row">
                        <div class="col col-lg-3 col-md-3 col-sm-10">
                            <span class="exercise-label">Pushups</span>
                        </div>
                        <div class="col-2">
                            <b-button type="button" class="info-btn btn-secondary" v-b-modal.pushups-modal><i class="bi bi-info-lg"></i></b-button>
                        </div>
                        <div class="col-md-6 col-sm-12 pt-10">
                            <b-form-input size='lg' type="number" v-model="inputData.pushups.reps" placeholder="Reps"
                                min="1" max="150" maxlength="3" required
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                            </b-form-input>
                        </div>
                    </div>
                </div>

                <div class="wc_pullups exercise-item" v-if="wc_pullups">
                    <div class="row">
                        <div class="col col-lg-3 col-md-3 col-sm-10">
                            <span class="exercise-label">Pullups</span>
                        </div>
                        <div class="col-2">
                            <b-button type="button" class="info-btn btn-secondary" v-b-modal.wc-pullups-modal><i
                                    class="bi bi-info-lg"></i></b-button>
                        </div>
                        <div class="col-md-6 col-sm-12 pt-10">
                            <b-form-input size='lg' type="number" v-model="inputData.wc_pullups.reps" placeholder="Reps"
                                min="0" max="40" maxlength="3" required
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                            </b-form-input>
                        </div>
                    </div>
                </div>

                <div class="hang exercise-item" v-if="hang">
                    <div class="row">
                        <div class="col col-lg-3 col-md-3 col-sm-10">
                            <span class="exercise-label">Hang</span>
                        </div>
                        <div class="col-2">
                            <b-button type="button" class="info-btn btn-secondary" v-b-modal.hangs-modal><i
                                    class="bi bi-info-lg"></i></b-button>
                        </div>
                        <div class="col-md-6 col-sm-12 pt-10">
                            <b-form-select v-model="inputData.hang.seconds" :options="hangSecondOptions"
                                size="lg" required>
                                <template #first>
                                    <b-form-select-option :value="null" disabled selected>Seconds</b-form-select-option>
                                </template>
                            </b-form-select>
                        </div>
                    </div>
                </div>

                <div class="dips exercise-item" v-if="dips">
                    <div class="row">
                        <div class="col col-lg-3 col-md-3 col-sm-10">
                            <span class="exercise-label">Dips</span>
                        </div>
                        <div class="col-2">
                            <b-button type="button" class="info-btn btn-secondary" v-b-modal.dips-modal><i
                                    class="bi bi-info-lg"></i></b-button>
                        </div>
                        <div class="col-md-6 col-sm-12 pt-10">
                            <b-form-input size='lg' type="number" v-model="inputData.dips.reps" placeholder="Reps"
                                min="1" max="150" maxlength="3" required
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                            </b-form-input>
                        </div>
                    </div>
                </div>

                <div class="weighted_carry exercise-item" v-if="weighted_carry">
                    <div class="row">
                        <div class="col col-lg-3 col-md-3 col-sm-10">
                            <span class="exercise-label">Weighted Carry</span>
                        </div>
                        <div class="col-2">
                            <b-button type="button" v-show="currentProfile != 'resilience'" class="info-btn btn-secondary" v-b-modal.weighted-carry-all-modal><i class="bi bi-info-lg"></i></b-button>
                            <b-button type="button" v-show="currentProfile === 'resilience'" class="info-btn btn-secondary" v-b-modal.weighted-carry-civilian-modal><i class="bi bi-info-lg"></i></b-button>
                        </div>
                        <div class="col-md-6 col-sm-1 pt-10">
                            <b-form-input size='lg' type="number" v-model="inputData.weighted_carry.seconds" placeholder="Seconds"
                                min="1" max="300" maxlength="3" required
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                            </b-form-input>
                        </div>
                    </div>
                </div>

                <div class="tgu exercise-item" v-if="tgu">
                    <div class="row">
                        <div class="col-10 col-lg-3 col-md-3 col-sm-10">
                            <span class="exercise-label">TGU</span>
                        </div>
                        <div class="col-2 col-lg-2 col-md-2 col-sm-2">
                            <b-button type="button" class="info-btn btn-secondary" v-b-modal.tgu-modal><i
                                    class="bi bi-info-lg"></i></b-button>
                        </div>
                        <div class="col-12 col-lg-6 col-md-6 col-sm-6 pt-10">
                            <b-form-input type="number" size='lg' v-if="weightUnit == 'lbs'" v-model="inputData.tgu.weight" placeholder="Weight (lbs)" min="1" max="999" maxlength="3" required 
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                            </b-form-input>
                            <b-form-input type="number" size='lg' v-if="weightUnit == 'kg'" v-model="inputData.tgu.weight" placeholder="Weight (kgs)" min="1" max="454" maxlength="3" required 
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
                            </b-form-input>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Modals -->
            
            <b-modal id="tp-work-capacity-modal" title="Testing Protocol" hide-footer size="xl" scrollable>
                <TPWorkCapacityModal />
            </b-modal>
            <b-modal id="pushups-modal" title="Pushups" hide-footer size="xl" scrollable>
                <PushupsModal />
            </b-modal>
            <b-modal id="wc-pullups-modal" title="Pullups" hide-footer size="xl" scrollable>
                <PullupsModal />
            </b-modal>
            <b-modal id="hangs-modal" title="Hangs" hide-footer size="xl" scrollable>
                <HangsModal />
            </b-modal>
            <b-modal id="dips-modal" title="Dips" hide-footer size="xl" scrollable>
                <DipsModal />
            </b-modal>
            <b-modal id="weighted-carry-all-modal" title="Farmers Carry" hide-footer size="xl" scrollable>
                <WeightedCarryAllModal />
            </b-modal>
            <b-modal id="weighted-carry-civilian-modal" title="Farmers Carry" hide-footer size="xl" scrollable>
                <WeightedCarryCivilianModal />
            </b-modal>
            <b-modal id="tgu-modal" title="Turkish Get Up" hide-footer size="xl" scrollable>
                <TurkishGetUpModal />
            </b-modal>
            
            <div class="step-controls row">
                <div class="col-12">
                    <b-button class="float-left" variant="secondary" size="lg" @click="onClickBack">Back</b-button>
                    <b-button type="submit" class="float-right" variant="primary" size="lg">Next</b-button>
                </div>
            </div>

        </b-form>

    </div>

</template>

<style lang="scss" scoped>
@media (max-width: 768px) {
    .pt-10 {
        margin-top: 10px;
    }
}
</style>

<script>
    import {
        mapState
    } from 'vuex'

    import StepTitle from '../StepTitle.vue';

    import TPWorkCapacityModal from '../modals/tp-work-capacity-modal.vue';
    import PushupsModal from '../modals/pushups-modal.vue'
    import PullupsModal from '../modals/wc-pullups-modal.vue'
    import DipsModal from '../modals/dips-modal.vue'
    import WeightedCarryAllModal from '../modals/weighted-carry-all-modal.vue'
    import WeightedCarryCivilianModal from '../modals/weighted-carry-civilian-modal.vue'
    import TurkishGetUpModal from '../modals/turkish-getup-modal.vue'
    import HangsModal from '../modals/hangs-modal.vue'

    import {
        filter,
        isNull,
        forEach,
        has
    } from 'lodash';

    export default {
        components: {
            StepTitle,
            TPWorkCapacityModal,
            PushupsModal,
            PullupsModal,
            DipsModal,
            WeightedCarryAllModal,
            WeightedCarryCivilianModal,
            TurkishGetUpModal,
            HangsModal
        },
        data() {
            return {
                pullups: null,
                pushups: null,
                dips: null,
                weighted_carry: null,
                hang: null,
                tgu: null,
                wc_pullups: null
            }
        },
        computed: {
            ...mapState([
                'inputData',
                'weightUnit',
                'currentProfile'
            ]),
        },
        mounted() {
            this.minuteOptions = this.generateOptions(1, 10);
            this.secondOptions = this.generateOptions(0, 59);
            this.hangSecondOptions = this.generateOptions(0,91);
            this.hangSecondOptions.push({
                'text': '90+',
                'value': 90
            })

            // Check what should be enabled
            forEach(this.$store.state.currentProfileConfigInputs, (key) => {
                if (has(this, key)) {
                    console.log('Enabling: ' + key);
                    this[key] = true;
                }
            });

            // console.log(this.$store.state.currentProfileConfigInputs)
            // this.wcPullupOptions = this.filterChoices(this.allWCPullups, this.$store.state.currentProfileConfigInputs);
            // this.autoSelectIfOnlyOneChoice(this.form.wc_pullups_type,this.wcPullupOptions);

            // this.deadlifts = this.filterChoices(this.allDeadlifts, this.$store.state.currentProfileConfigInputs);
            // this.presses = this.filterChoices(this.allPresses, this.$store.state.currentProfileConfigInputs);

            // console.log(this.wc_pullups);

        },
        methods: {
            generateOptions(min, max) {
                let temp = [];
                for (let i = min; i < max; i++) {
                    temp.push({
                        text: i,
                        value: i
                    });
                }
                return temp;
            },
            autoSelectIfOnlyOneChoice(typeVal, options) {
                // if(this.wcPullupOptions.length < 2) {
                //     console.log(this.wcPullupOptions[1]);
                //     this.form.wc_pullups_type = this.wcPullupOptions[1].value;
                //     console.log(this.form.wc_pullups_type)
                // }
                let filtered = filter(options, function (o) {
                    return !isNull(o.value);
                });
                if (filtered.length == 1) {
                    typeVal = options[1].value;
                }
            },
            filterChoices(choices) {
                return filter(choices, (option) => {
                    if (this.$store.state.currentProfileConfigInputs.indexOf(option.value) > -1 || option
                        .value == null) {
                        return option;
                    }
                })
            },
            onSubmit(e) {
                e.preventDefault();
                this.$store.dispatch('nextStep');
                console.log(this.inputData);
            },
            onClickBack() {
                this.$store.dispatch('previousStep');
            }
        }
    }
</script>