import Vue from 'vue'
import Vuex from 'vuex'

import axios from 'axios';
import convertLib from './convert';

import CONFIG_DATA from '../../functions/config.json';

import {
  saveAs
} from 'file-saver';
import {
  get,
  set,
  keys,
  cloneDeep,
  forOwn,
  isNull
} from 'lodash';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    config: null,
    currentProfile: null,
    currentProfileConfig: null,
    currentStepIndex: null,
    currentProfileConfigSteps: [
      "intro",
      "profile-select",
      "strength",
      "work-capacity",
      "conditioning",
      "movement",
      "recovery",
      "finished"
    ],
    currentProfileConfigInputs: null,
    inputData: {},
    emptyInputData: {
      squatType: null,
      deadliftType: null,
      upperBodyPushType: null,
      upperBodyPullType: null,

      back_squat: {
        reps: null,
        weight: null
      },
      front_squat: {
        reps: null,
        weight: null
      },
      goblet_squat: {
        weight: null
      },
      rfe_split_squat_x8: {
        weight: null
      },
      trap_bar_dl: {
        reps: null,
        weight: null
      },
      straight_bar_dl: {
        reps: null,
        weight: null
      },
      rfe_split_squat: {
        reps: null,
        weight: null
      },
      db_bench_press: {
        weight: null
      },
      landmine_press_x5: {
        weight: null
      },
      db_row_x5: {
        weight: null
      },
      db_row: {
        weight: null
      },
      bench_press: {
        reps: null,
        weight: null
      },
      kb_oh_press: {
        reps: null,
        weight: null
      },
      landmine_press: {
        reps: null,
        weight: null
      },
      pullups: {
        reps: null,
        weight: null
      },
      broad_jump: {
        feet: null,
        inches: null,
        meters: null
      },
      pushups: {
        reps: null
      },
      wc_pullups: {
        reps: null
      },
      hang: {
        seconds: null
      },
      dips: {
        reps: null
      },
      weighted_carry: {
        seconds: null
      },
      tgu: {
        weight: null
      },

      c_8m_ruck: {
        hours: null,
        minutes: null
      },
      c_10k_row_or_ski: {
        hours: null,
        minutes: null
      },
      c_3m_run: {
        minutes: null,
        seconds: null
      },
      c_2m_run: {
        minutes: null,
        seconds: null
      },
      c_10k_row: {
        hours: null,
        minutes: null
      },
      c_4m_ruck: {
        hours: null,
        minutes: null
      },
      c_5m_run: {
        hours: null,
        minutes: null
      },
      c_15m_run: {
        minutes: null,
        seconds: null
      },
      c_400m_run: {
        minutes: null,
        seconds: null
      },
      c_2000m_row: {
        minutes: null,
        seconds: null
      },
      c_2000m_swim: {
        minutes: null,
        seconds: null
      },
      c_500m_swim: {
        minutes: null,
        seconds: null
      },
      c_500m_row: {
        minutes: null,
        seconds: null
      },
      c_500m_row_or_ski: {
        minutes: null,
        seconds: null
      },

      q1: {
        answer: null
      },
      q2: {
        answer: null
      },
      q3: {
        answer: null
      },
      q4: {
        answer: null
      },
      toe_touch: {
        points: null
      },
      split_squat: {
        points: null
      },
      overhead_lunge: {
        points: null
      },

      sleep_1: {
        val: null
      },
      sleep_2: {
        val: null
      },
      stress_1: {
        val: null
      },
      stress_2: {
        val: null
      },
      nutrition_1: {
        val: null
      },
      nutrition_2: {
        val: null
      },
    },
    formData: {
      unit: 'imperial',
      // email: null,
      // name: null,
      // weight: null,
      // type: null
      // email: 'test@gmail.com',
      // name: 'Test 2',
      // weight: 200,
      // type: 'sof-land'
    },
    weightUnit: 'lbs',
    distanceUnit: 'ft',
    isReportGenerated: null,
  },
  mutations: {
    setConfig(state, newValue) {
      state.config = newValue;

      // Find current index
      // try {
      // } catch(e) {
      // state.currentStepIndex = 0;
      // }
    },
    setCurrentStep(state, newValue) {
      state.currentStepIndex = newValue;

      // Get current location
      let currentPath = window.location.hash.replace('#/','');

      // Find current index of route
      let currentIndex = state.currentProfileConfigSteps.indexOf(currentPath);
      console.log('currentIndex');
      console.log(currentIndex);
    },
    setCurrentProfile(state, newValue) {

      // If the user switched profiles, reset the form data
      if (state.currentProfile != newValue) {
        state.currentProfile = newValue;

        // Empty Input Data
        state.inputData = cloneDeep(state.emptyInputData);
        // state.formData = {
        //   unit: 'imperial'
        // };
      }

      // console.log(state.config);
      state.currentProfileConfig = get(state.config.profiles, newValue);
      // console.log(state.currentProfileConfig);
      state.currentProfileConfigSteps = get(state.currentProfileConfig, 'steps');
      state.currentProfileConfigInputs = keys(get(state.currentProfileConfig, 'inputs'));
      console.log(state.currentProfileConfigInputs);
      state.formData.type = newValue;
      console.log('Form Data')
      console.log(state.formData);
    },
    setUserData(state, newValue) {
      console.log('setUserData');
      state.formData.email = get(newValue, 'email');
      state.formData.name = get(newValue, 'name');
      state.formData.weight = get(newValue, 'weight');
      state.formData.unit = get(newValue, 'unit');
      console.log(state.formData);
    },
    setUnitPreference(state, unit) {
      state.formData.unit = unit;
      if (unit == 'imperial') {
        state.weightUnit = 'lbs';
        state.distanceUnit = 'ft';
      } else {
        state.weightUnit = 'kg';
        state.distanceUnit = 'm';
      }
    },
    setFormData(state, newValue) {
      console.log('setFormData');
      forOwn(newValue,(o, k) => {
        let val = get(newValue,k)
        if(!isNull(val) && !isNaN(val)) {
          set(state.formData,k,o);
        }
      });
      console.log(state.formData);
    },
    setReportGenerationStatus(state, newValue) {
      state.isReportGenerated = newValue;
    },
    initializeStore(state) {
      // Check if the ID exists
      if (localStorage.getItem('store')) {
        console.log('Found Previous Session - Loading data')
        // Replace the state object with the stored item
        this.replaceState(
          Object.assign(state, JSON.parse(localStorage.getItem('store')))
        );
      } else {
        console.log('No Previous Session - Initializing data')
      }
    },
    setInputData(state, payload) {
      console.log('setInputData');
      console.log(payload);
      set(state.inputData, get(payload, 'key'), get(payload, 'val'));
      console.log(state.inputData);
    }
  },
  actions: {
    async getConfig({
      commit
    }) {
      let result = CONFIG_DATA;
      commit('setConfig', result);
      return result;
    },
    async setCurrentStep({
      commit
    }, newValue) {
      commit('setCurrentStep', newValue);
    },
    async nextStep({
      commit,
      state
    }) {
      console.log('nextStep');
      let temp = (state.currentStepIndex + 1);
      commit('setCurrentStep', temp);
      console.log(this.state.inputData);
    },
    async previousStep({
      commit,
      state
    }) {
      console.log('previousStep');
      let temp = (state.currentStepIndex - 1);
      commit('setCurrentStep', temp);
    },
    // async detectedBack({
    //   commit,
    //   state
    // }, newRoute) {
    //   console.log('detectedBack');
    //   console.log(state.currentProfileConfigSteps);
    //   console.log(newRoute);
    //   if(newRoute != '/') {
    //     let currentStepIndex = state.currentProfileConfigSteps.indexOf(newRoute) + 1;
    //     // let temp = (state.currentStepIndex - 1);
    //     commit('setCurrentStep', currentStepIndex);
    //   } else {
    //     commit('setCurrentStep', 0);
    //   }
    // },
    async startOver({
      commit
    }) {
      console.log('startOver');
      commit('setCurrentStep', 0);
    },
    async setCurrentProfile({
      commit
    }, profile) {
      console.log('setCurrentProfile: ' + profile);
      commit('setCurrentProfile', profile);
    },
    async setUnitPreference({
      commit
    }, unit) {
      commit('setUnitPreference', unit);
    },
    async setUserData({
      commit
    }, payload) {
      commit('setUserData', payload);
    },
    async updateInputData({
      commit
    }, payload) {
      console.log('updateInputData');
      commit('setInputData', payload);
    },
    async generateReport({
      commit
    }) {
      console.log('generateReport');
      commit('setReportGenerationStatus', false);
      
      // Convert Input Data to Data to Be Sent
      console.log('saveFormData');
      
      console.log('inputData');
      console.log(this.state.inputData);

      let myFormData = convertLib.processInputData(this.state.formData.unit,this.state.inputData);
      set(myFormData,'type',this.state.formData.type);

      // Set user data
      set(myFormData,'email',this.state.formData.email);
      set(myFormData,'name',this.state.formData.name);
      set(myFormData,'weight',this.state.formData.weight);

      // commit('setFormData',myFormData);

      console.log('Submitting Data');
      console.log(myFormData);

      // Send Data to Server
      const url = process.env.VUE_APP_API_HOST + '/convertToPDF';
      const response = await axios.post(url, myFormData, {
        responseType: 'blob'
      });
      saveAs(response.data, 'building-the-elite-profile.pdf');
      commit('setReportGenerationStatus', true);
      // const blob = new Blob([response.data], { type: "application/pdf" });
      // const link = document.createElement("a");
      // link.href = URL.createObjectURL(blob);
      // link.target = "_blank";
      // link.click();
      // URL.revokeObjectURL(link.href);
    },
    async clearReport({commit}) {
      commit('setReportGenerationStatus', null);
    }
  }
})

// Subscribe to store updates
store.subscribe((mutation, state) => {
  // Store the state object as a JSON string
  localStorage.setItem('store', JSON.stringify(state));
});

export default store;