<template>
    <div class="container step-container">
        <StepTitle title="Complete" />
        <div class="step-copy">
            <p class="tc">Click the Get Report button below to get your custom profile scores.<br>Do not hit back. It may take 1-2 minutes for your report to load.</p>
        </div>  

        <div v-if="isReportGenerated == false">
            <b-spinner label="Loading..."></b-spinner>
            
            <div class="step-copy loading-text">
                <p class="tc">Processing your assessment...</p>
            </div>
        </div>

        <b-button size="lg" class="center" variant="primary" @click="generateReport" v-if="isReportGenerated == null"><i class="bi bi-download"></i>Get Report</b-button>
        
    </div>

</template>

<style lang="scss" scoped>
.spinner-border {
    margin: auto;
    display: block;
}
.step-copy p{
    font-size: 20px;
}
.loading-text {
    margin-top: 20px;
}
</style>

<script>
import {
    mapState
} from 'vuex'

import StepTitle from '../StepTitle.vue';

export default {
    components: {
        StepTitle
    },
    data() {
      return {
        form: {
        }
      }
    },
    computed: {
        ...mapState([
            'isReportGenerated'
        ]),
    },
    mounted() {
        this.$store.dispatch('clearReport')
    },
    methods: {
        generateReport() {
            this.$store.dispatch('generateReport');
        },
        onReset(e) {
            e.preventDefault();
            this.form = {
                squatType: null
            }
        }
    }
}
</script>