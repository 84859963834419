<template>
    <div class="container step-container">
        <b-form @submit="onSubmit" >
            <StepTitle title="Movement" />
            <div class="step-copy">
                <p>Movement is the most difficult physiological capacity to assess and train, probably because it
                    requires a sustained and effortful commitment to its improvement. Your mitochondrial density will
                    happily become more efficient without you knowing what mitochondria even are. Your squat mechanics
                    will not. </p>
                <p>The human body has many ways to accomplish a movement (i.e. variability). This redundancy, alongside
                    person-to-person differences in skeletal structure, means that there are nearly endless ways the
                    body can create motion. </p>
                <p>Some pathways of creating movement are more efficient than others, which is why you should care about
                    movement capacity (total movement available to you) and fidelity (how well you maintain movement
                    quality) - not just your ability to complete a task. But: quantifying human movement in a reliable
                    and simple way is tricky, if not impossible. </p>
                <p>Not only is movement hard to measure accurately, but how much capacity an individual needs to avoid
                    injury is idiosyncratic (i.e. specific to that individual), and this capacity is often deployed into
                    an unpredictable environment. Two individuals with very similar physiological capabilities,
                    including movement capacity, will break down in different areas at different times in their careers.
                </p>
                <p>The questions and assessments below are a good starting point for understanding if and how your
                    movement work needs to be modified. </p>
            </div>

            <span class="exercise-label">READ THE INFO BUTTONS TO MAKE SURE YOU ARE DOING THE TESTS PROPERLY!</span>

            <div class="step-inputs">
                <div class="section-heading">Practical Questions</div>

                <div class="q1" >
                    <div class="exercise-label">Do you have any training-related movements you struggle to execute
                        because you can’t get into position or experience pain while doing them? For example, you can’t
                        touch your chest to the bar on a pull-up or you have knee pain when rucking.</div>
                    <div class="row" >
                        <div class="col-6 radio">
                            <b-form-group >
                                <b-form-radio-group name="q1" v-model="q1" :options="yesNoOptions" size="lg" @change="onRadioChange(q1,'q1.answer')">
                                </b-form-radio-group>
                            </b-form-group>
                        </div>
                    </div>
                </div>

                <div class="q2" >
                    <div class="exercise-label">Outside of normal soreness (diffuse tenderness through a large region or muscle group), do you have any pain or tension on a recurring basis in the same areas? For example, your right shoulder hurts every time you do pull-ups.</div>
                    <div class="row" >
                        <div class="col-6 radio">
                            <b-form-group >
                                <b-form-radio-group name="q2" v-model="q2" :options="yesNoOptions" size="lg" @change="onRadioChange(q2,'q2.answer')">
                                </b-form-radio-group>
                            </b-form-group>
                        </div>
                    </div>
                </div>

                <div class="q3" >
                    <div class="exercise-label">Have you had to stop or significantly alter your training due to an injury in the past six months? For example, you got shin splints and had to stop running for three weeks.</div>
                    <div class="row" >
                        <div class="col-6 radio">
                            <b-form-group >
                                <b-form-radio-group name="q3" v-model="q3" :options="yesNoOptions" size="lg" @change="onRadioChange(q3,'q3.answer')">
                                </b-form-radio-group>
                            </b-form-group>
                        </div>
                    </div>
                </div>

                <div class="q4" >
                    <div class="exercise-label">Do you avoid any type of exercise or training you know would be beneficial for your goals because you’re concerned about injuring yourself?</div>
                    <div class="row" >
                        <div class="col-6 radio">
                            <b-form-group >
                                <b-form-radio-group name="q4" v-model="q4" :options="yesNoOptions" size="lg" @change="onRadioChange(q4,'q4.answer')">
                                </b-form-radio-group>
                            </b-form-group>
                        </div>
                    </div>
                </div>

                <div class="section-heading">Movement Tests</div>

                <div class="instructions"><b>Click on the info button for instructions for each movement test.</b></div>

                <div class="toe_touch exercise-item" >
                    <div class="exercise-label">How many points did you get on assessment 1?</div>
                    <div class="row" >
                        <div class="col col-lg-2 col-md-2 col-sm-2">
                            <b-button type="button" class="info-btn btn-secondary v-center" v-b-modal.toe_touch-modal><i class="bi bi-info-lg"></i></b-button>
                        </div>
                        <div class="col col-lg-6 col-md-6 col-sm-10">
                            <b-form-select v-model="toe_touch" :options="pointOptions" size="lg" required @change="onPointChange(toe_touch,'toe_touch')">
                                <template #first>
                                    <b-form-select-option :value="null" disabled selected>Points</b-form-select-option>
                                </template>
                            </b-form-select>
                        </div>
                    </div>
                </div>

                <div class="split_squat exercise-item" >
                    <div class="exercise-label">How many points did you get on assessment 2?</div>
                    <div class="row" >
                        <div class="col col-lg-2 col-md-2 col-sm-2">
                            <b-button type="button" class="info-btn btn-secondary v-center" v-b-modal.split_squat-modal><i class="bi bi-info-lg"></i></b-button>
                        </div>
                        <div class="col col-lg-6 col-md-6 col-sm-10">
                            <b-form-select v-model="split_squat" :options="pointOptions" size="lg" required @change="onPointChange(split_squat,'split_squat')">
                                <template #first>
                                    <b-form-select-option :value="null" disabled selected>Points</b-form-select-option>
                                </template>
                            </b-form-select>
                        </div>
                    </div>
                </div>

                <div class="split_overhead_lungesquat exercise-item" >
                    <div class="exercise-label">How many points did you get on assessment 3?</div>
                    <div class="row" >
                        <div class="col col-lg-2 col-md-2 col-sm-2">
                            <b-button type="button" class="info-btn btn-secondary v-center" v-b-modal.overhead_lunge-modal><i class="bi bi-info-lg"></i></b-button>
                        </div>
                        <div class="col col-lg-6 col-md-6 col-sm-10">
                            <b-form-select v-model="overhead_lunge" :options="pointOptions" size="lg" required @change="onPointChange(overhead_lunge,'overhead_lunge')">
                                <template #first>
                                    <b-form-select-option :value="null" disabled selected>Points</b-form-select-option>
                                </template>
                            </b-form-select>
                        </div>
                    </div>
                </div>

            </div>

            <div class="step-controls row">
                <div class="col-12">
                    <b-button class="float-left" variant="secondary" size="lg" @click="onClickBack">Back</b-button>
                    <b-button type="submit" class="float-right" variant="primary" size="lg" >Next</b-button>
                </div>
            </div>

        </b-form>

        <!-- Modals -->
        <b-modal id="toe_touch-modal" title="Toe Touch" hide-footer size="xl" scrollable>
            <ToeTouchModal />
        </b-modal>
        <b-modal id="split_squat-modal" title="Split Squat" hide-footer size="xl" scrollable>
            <SplitSquatModal />
        </b-modal>
        <b-modal id="overhead_lunge-modal" title="Overhead Lunge" hide-footer size="xl" scrollable>
            <OverheadLungeModal />
        </b-modal>

    </div>

</template>

<style lang="scss" scoped>
.radio {
    margin: 10px;
    margin-top: 20px;
}
.instructions {
    margin-bottom: 40px;
}
</style>

<script>
    import {
        mapState
    } from 'vuex'

    import StepTitle from '../StepTitle.vue';
    
    import ToeTouchModal from '../modals/toe-touch-modal.vue'
    import SplitSquatModal from '../modals/split-squat-modal.vue'
    import OverheadLungeModal from '../modals/overhead-lunge-modal.vue'

    import {
        forEach, has, get
    } from 'lodash';

    export default {
        components: {
            StepTitle,
            ToeTouchModal,
            SplitSquatModal,
            OverheadLungeModal
        },
        data() {
            return {
                yesNoOptions: [{
                        text: 'No',
                        value: 'no'
                    },
                    {
                        text: 'Yes',
                        value: 'yes'
                    }
                ],
                pointOptions: [{
                        text: '0',
                        value: 0
                    },
                    {
                        text: '1',
                        value: 1
                    },
                    {
                        text: '2',
                        value: 2
                    },
                    {
                        text: '3',
                        value: 3
                    },
                    {
                        text: '4',
                        value: 4
                    }
                ],
                q1: null,
                q2: null,
                q3: null,
                q4: null,
                toe_touch: null,
                split_squat: null,
                overhead_lunge: null,
            }
        },
        computed: {
            ...mapState([
                'inputData'
            ])
        },
        mounted() {

            // Load values from inputData in state
            let list = ['q1', 'q2', 'q3', 'q4','split_squat','overhead_lunge','toe_touch'];
            forEach(list, (key) => {
                console.log(key);
                if(has(this,key)) {
                    let myVal = null;
                    if(has(this.$store.state.inputData,(key+'.answer'))) {
                        myVal = get(this.$store.state.inputData,(key+'.answer'));
                    }
                    if(has(this.$store.state.inputData,(key+'.points'))) {
                        myVal = get(this.$store.state.inputData,(key+'.points'));
                    }
                    this[key] = myVal;
                }
            });

        },
        methods: {
            onSubmit(e) {
                e.preventDefault();
                this.$store.dispatch('nextStep');
            },
            onClickBack() {
                this.$store.dispatch('previousStep');
            },
            onRadioChange(val,key) {
                this.$store.dispatch('updateInputData', {
                    key: key,
                    val: val
                });
            },
            onPointChange(val,path) {
                this.$store.dispatch('updateInputData', {
                    key: path+'.points',
                    val: val
                });
            }
        }
    }
</script>